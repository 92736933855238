@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.site{
    min-height:100vh;
    margin-bottom: -250px;

}
footer, .minsite{
    height:250px;
}

@media only screen and (max-width:720px){
    .site{
        min-height:100vh;
        margin-bottom: -400px;
    }
        footer, .minsite{
            height:400px;
        }
    }
* {
    box-sizing: border-box;
}
.scalo {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
}
.sone1,
 .sone2,
  .sone3,
   .sone4,
    .sone5,
     .stwo {

    position: absolute;
    width:80%;
    height: 85%;
    margin-right:10%;
    margin-left: 10%;
    margin-top: 5%;
    margin-bottom: 0%;
    z-index: 1;
    background-position: center;
}

.sone1 {
    
    background-image: url(/static/media/etude.60ba8706.jpg);
    
}
.sone2 {
    
    background-image: url(/static/media/installation.fe79d703.jpg);
   

}
.sone3 {
   
    background-image: url(/static/media/maintenance.01f66e8d.jpg);
   

}

.sone4 {
    
    background-image: url(/static/media/controle.5f78e136.jpg);
   

}

   

.stwo {
   
    background-color: rgba(13, 18, 20, 0.541);
    z-index: 2;

   
   
}
.sthree {
    position: absolute;
    width: 200px;
    height: 100px;
    left: 33%;
    top: 40%;
    z-index: 3;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size:67px;
    text-transform: uppercase;
  

  
  
}

.plus {
    position: absolute;
    width: 240px;
    height: 50px;
    z-index: 3;
    font-family: sans-serif;
    font-weight: bolder;
    font-size: 22px;
    color: white;
    left: 58%;
    top: 80%;
    text-decoration: underline;

}

@media (max-width:991px) {

    .sthree {
     
        width: 500px;
        height: 30px;
        left: 25%;
        top: 20%;
        z-index: 3;
        color: white;
        font-family: Arial, Helvetica, sans-serif;
       font-size: 20px;
        text-transform: uppercase;
    }
       

.scalo {
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
}

}
.root_projet {
    position: relative;
    height: 1000px;
    background-image: url(/static/media/bg-ing.2fef5a94.jpg);
    background-position: center;
}
.btn {
    position: absolute;
    margin-top: 31%!important;
    left: 61%;
    background-color:#fa0f0c;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
  }
  
  .btn:hover {
      opacity: 1;
     
}

  .title_project {
      position: absolute;
      margin-top: 7%;
      left: 65%;
      font-weight: bolder;
      font-size: 28px;
      font-family: sans-serif;
      color: #fa0f0c;

  }
  .slogan_project {
      position: absolute;
      margin-top: 16%;
      left: 10%;
      font-size: 20px;
      color:rgb(2, 2, 36);
      font-weight: bold;
  }
  .bar-color-projet {
    left: 7%; 
    margin-top: 5%;
    position:absolute;
    width: 15px;
    height: 50px;
    background-color: #fa0f0c;
    height: 145px;
    /* animation: mymove 5s infinite; */
}
/* @keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
} */
  /* Projet 1 */
  .img_project1 {
    position: absolute;
    margin-top: 44%;
    left: 16%;
    width: 80%;
    height: 570px;
    display: block;
    transition: .5s ease;
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    cursor: pointer;
 
  }
/* overlay effect */
.overlay1 {
  position: absolute;
  margin-top: 44%;
  left: 16%;
  width: 80%;
  height: 570px;
  opacity: 0;
  transition: .5s ease;
  background-color: #090b6d5e;
  cursor: pointer;
}

.projet_1:hover .overlay1 {
  opacity: 1;
}
.title_project_child1 {
    position: absolute;
    z-index: 6;
    text-transform: uppercase;
    font-size: 34px;
    font-weight: bold;
    left: 21%;
    margin-top: 90%;
    color: white;
}
.bar-color-projet_2 {
    left: 0%;
    margin-top: 12%;
    position: absolute;
    width: 91px;
    height: 5px;
    background-color: #fa0f0c;
}




/* ===== */

/* projet2 */
.img_project2 {
  position: absolute;
  margin-top: 90px;
  left: 2%;
  width: 80%;
  height: 400px;
  cursor: pointer;
}
/* overlay effect */
.overlay2 {
  position: absolute;
  margin-top: 90px;
  left: 2%;
  width: 80%;
  height: 400px;
  opacity: 0;
  transition: .5s ease;
  background-color: #090b6d5e;
  cursor: pointer;
}

.projet_2:hover .overlay2 {
  opacity: 1;
}
.title_project_child2 {
        color: white;
        font-size: 29px;
        position: absolute;
        margin-top: 57%;
        left: 36%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        text-align: center;
        z-index: 6;
        font-weight: bold;
        text-transform: uppercase;
    }
/* ============================================= */

/* projet2 */
.img_project3 {
  position: absolute;
  margin-top: 517px;
  left: 2%;
  width: 80%;
  height: 400px;
  cursor: pointer;
}
/* overlay effect */
.overlay3 {
  position: absolute;
  margin-top: 517px;
  left: 2%;
  width: 80%;
  height: 400px;
  opacity: 0;
  transition: .5s ease;
  background-color: #090b6d5e;
  cursor: pointer;
}

.projet_3:hover .overlay3 {
  opacity: 1;
}
.title_project_child3 {
    color: white;
    position: absolute;
    margin-top: 110%;
    left: 36%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    text-align: center;
    z-index: 6;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
}
/* ============================================= */

@media (max-width : 767px) {

    .root_projet {
        position: relative;
        height: 1500px;
        background-image: url(/static/media/bg-ing.2fef5a94.jpg);
        background-position: center;
    }
    .btn {
        position: absolute;
        margin-top: 49%!important;
        left: 37%;
        background-color:#fa0f0c;
        border: none;
        color: white;
        padding: 16px 32px;
        text-align: center;
        font-size: 16px;
        margin: 4px 2px;
        opacity: 0.6;
        transition: 0.3s;
      }
      
      .btn:hover {
          opacity: 1;
         
    }
    
      .title_project {
          position: absolute;
          margin-top: 7%;
          left: 15%;
          font-weight: bolder;
          font-size: 28px;
          font-family: sans-serif;
          color: #fa0f0c;
    
      }
      .slogan_project {
          position: absolute;
          margin-top: 16%;
          left: 15%;
          font-size: 20px;
          color:rgb(2, 2, 36);
          font-weight: bold;
      }
      .bar-color-projet {
        left: 7%; 
        margin-top: 5%;
        position:absolute;
        width: 15px;
        height: 50px;
        background-color: #fa0f0c;
        height: 145px;
    }
      /* Projet 1 */
      .img_project1 {
        position: absolute;
        margin-top: 72%;
        left: 11%;
        width: 80%;
        height: 350px;
        display: block;
        transition: .5s ease;
        -webkit-backface-visibility: hidden;
                backface-visibility: hidden;
        cursor: pointer;
     
      }
    /* overlay effect */
    .overlay1 {
      position: absolute;
      margin-top: 72%;
      left: 11%;
      width: 80%;
      height: 350px;
      opacity: 0;
      transition: .5s ease;
      background-color: #0003ba5e;
      cursor: pointer;
    }
    
    .projet_1:hover .overlay1 {
      opacity: 1;
    }
    .title_project_child1 {
      color: white;
      font-size: 20px;
      position: absolute;
      top: 80%;
      left: 65%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
    
    
    /* ===== */
    
    /* projet2 */
    .img_project2 {
        position: absolute;
        margin-top: 653px;
        left: 11%;
        width: 80%;
        height: 350px;
        cursor: pointer;
    }
    /* overlay effect */
    .overlay2 {
      position: absolute;
      margin-top: 653px;
      left: 11%;
      width: 80%;
      height: 350px;
      opacity: 0;
      transition: .5s ease;
      background-color: #0003ba5e;
      cursor: pointer;
    }
    
    .projet_2:hover .overlay2 {
      opacity: 1;
    }
    .title_project_child2 {
      color: white;
      font-size: 20px;
      position: absolute;
      top: 90%;
      left: 65%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
    /* ============================================= */
    
    /* projet2 */
    .img_project3 {
      position: absolute;
      margin-top: 1010px;
      left: 11%;
      width: 80%;
      height: 350px;
      cursor: pointer;
    }
    /* overlay effect */
    .overlay3 {
      position: absolute;
      margin-top: 1010px;
      left: 11%;
      width: 80%;
      height: 350px;
      opacity: 0;
      transition: .5s ease;
      background-color: #0003ba5e;
      cursor: pointer;
    }
    
    .projet_3:hover .overlay3 {
      opacity: 1;
    }
    .title_project_child3 {
      color: white;
      font-size: 20px;
      position: absolute;
      top: 90%;
      left: 65%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }

} 
 

 

*{
    box-sizing: border-box;
}
.slide_qhse {
    display: flex;
    width: 100%;
    height: 400px;
    background-image: url(/static/media/qhse.e7e14a05.jpg);
    background-position: center;
    justify-content: center;
    align-items: flex-start;
    
}

.filtre_qhse {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(8, 32, 43, 0.61); 
  }

  .title_rubrique_qhse {
      position: relative;
      left:1%;
      top: 40%;
      width: 50%;
      height: 100px; 
      color: white;
      font-size: 55px;
      -webkit-animation: slide-up 3s;
              animation: slide-up 3s;
      text-shadow: 0 0 5px #080707;
    
    }
    @-webkit-keyframes slide-up {
        from {
            opacity: 0.2;
            /* line-height: 200px; */
            left:-20%;
        }
        to {
            opacity: 1;
            /* line-height: 50px; */
            left:1%;
        }
    }
    @keyframes slide-up {
        from {
            opacity: 0.2;
            /* line-height: 200px; */
            left:-20%;
        }
        to {
            opacity: 1;
            /* line-height: 50px; */
            left:1%;
        }
    }

  
.qhse_root {
    background-image: url(/static/media/bg-ing.2fef5a94.jpg);
    display: flex;
    background-color: rgb(240, 240, 240);
    background-position: center; 
    padding: 20px;
    width: 100%;
    /* height: 600px; */
    padding: 25px;
}
.left_qhse {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
}
.left_top_qhse {
  
  display: flex;
  flex-direction: column; 
  height: 100%;
  justify-content: center; 
  align-items: center;

}
.entete_qhse_left {
    display: flex;
    padding:20px;
}
.etlaqualite {
    color: #1A4086;
    font-weight: bold;
    font-size: 30px;
    text-transform: uppercase;
}
.txt_qualite_qhse {
    font-size: 20px;
    color: white;
    width: 80%;
    background-color: #1a40868a;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.493);
}
.left_bottom_qhse {
    display: flex;
    flex-direction: column; 
    height: 0%;
    justify-content: center; 
    align-items: center;  
}
.right_qhse {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.right_top_qhse {
    display: flex;
    flex-direction: column; 
    height: 100%;
    justify-content: center; 
    align-items: center;  
}
.right_bottom_qhse {
    display: flex;
    flex-direction: column; 
    height: 0%;
    justify-content: center; 
    align-items: center;  
}
.slogan_hse {
    color: #fa0f0c;
    font-weight: bold;
    text-transform: uppercase;
    
}

.puce2 {
  
    position: relative;
    left:90%;
}

.rochdi_fondateur {
    position: relative;
    left: 50%;
    padding-top: 25px;
    padding-bottom: 25px;
    color: rgb(16, 16, 44);
    
}

.rochdi {
    font-family: italic, cursive;
    font-weight: bold;
}

.resp_hse {
    position: relative;
    font-family: italic, cursive;
    left: 55%;
    font-weight: bold;
    color: rgb(16, 16, 44);
}
@media (max-width : 767px) {

    .qhse_root {
  
        flex-direction: column;
      
       
    }
    .left_qhse,
    .right_qhse  {
        width: 100%; }
        .rochdi_fondateur ,
        .resp_hse {
            left: 0%;    
        }
}





*{
    box-sizing: border-box;
}
.root_contact{
    display: flex;
    width: 100%;
    height: 700px;
}
.left_contact,
.right_contact {
    width: 50%;
}
.right_contact iframe {
    width: 100%;
    height: 100%;
}
.left_contact {
    background-color: #1a40863d;
    flex-direction: column;
}
.left_top_contact {
    height: 50%;
    display: flex;
    flex-direction:column ;
}
.entete_contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #9e9e9e54;
    border-radius: 10px;
    width: 80%;
    padding: 30px;
}

.title_contact {

    font-size: 35px;
    font-weight: bold;
    color: black;
    padding: 25px;
    position:relative;
    left:40%;
    text-transform: uppercase;

}
.trait_contact {
    top: -9%;
    background-color: #fa0f0c;
    width: 15%;
    height: 2px;
    position: relative;
    left: 43%;
}
.adr_contact {
    font-size: 20px;

}
.tél{
    font-size: 20px;
    font-weight: bold;
}
.num_tél {
    font-size: 20px;
}
.email {
    font-size: 20px;
    font-weight: bold;
}
.adr_email {
    font-size: 20px;
}
.form_contact {
    width: 100%;
    padding: 10px;
    height: 50%;

}
/* this class for a btn inside it tag <a> btn take the classname ==teslabtnSlide== */
.btn_contact .link-button{
    text-decoration: none;
    color: white !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold !important;
    letter-spacing: 1.1px;
    z-index: 5;
        
    }
    .btn_contact{
    border: 0;
    outline: 0 !important;
    background-color:#fa100ca9;
    min-width: 175px;
    border-radius: 30px;
    
    position: relative;
    transition: all 1s ease-in-out;
    max-height: 56px;
    left: 40%;
    padding: 10px;
    z-index: 1;
    }
    
    .btn_contact::before {
    content: "";
    border: white 2px solid;
    width: 96%;
    height: 87%;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
    transition:  opacity 0.33s  ease-in-out;
    z-index: -1;
    }
    
    .btn_contact:hover:before{
    opacity: 1;
    }
    
    /* =================================== */
    @media (max-width : 767px) {
        .root_contact{
            display: flex;
            flex-direction: column-reverse;
            height: 1100px;
        }
        .left_contact
        {   height: 70%;
            width: 100%;
}
.right_contact iframe {
    width: 100%;
  

}
.right_contact {
    display: flex;
    width: 100%;
    height:30%;
}
.title_contact {
   
    left: 23%;
    
}
.btn_contact {
  
    left: 24%;
   
}
    }

.map-container{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    }
    .map-container iframe{
    margin-left:auto;
    margin-right: auto;
    top:0;
    height:100%;
    width:100%;
    position:relative;
    }
*{
    box-sizing: border-box;
}
.entete_ing{
    display: flex;
    width: 100%;
    padding: 20px;
    background-color: #e4e7e9;
}
.trait_deco {
    background-color: #fa0f0c;
    width: 3px;
    height: 145px;
    margin-left: 11%;
}
.entete_ing p {
    width: 70%;
    padding: 10px;
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 18px;
    
}
.bg_entete {
   
   height: 395px;
}
.bg_entete .slogan_ing {
    position: relative;
    left: 10%;
    top:-50%;
    color: white;
    text-transform: uppercase;
    font-size: 55px !important;
    font-weight: bold;
    -webkit-animation: slide-up 3s;
            animation: slide-up 3s;
    text-shadow: 0 0 5px #080707;
  
  }
  @-webkit-keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:10%;
      }
  }
  @keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:10%;
      }
  }
  .ing_element {
  margin: 20px 15% ;
  background-color: rgba(128, 128, 128, 0.151);
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.493);
  }



.fond2 {
    background-image: url(/static/media/bg-ing.2fef5a94.jpg);
    width: 100%;
}

.image_ing {
    display: flex;
    
}
.image_ing img {
    width: 90%;
    height: 225px;
    margin: 15px;
}

.txt_ing {
    display: flex;
    margin: 15px;
    width: 60%;
    
    
}
.trait_deco_description {
    background-color: #fa0f0c;
    width: 3px;
    height: 130px;
}
.txt_ing .description_ing {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px;
}
.description_ing p {
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 16px;
}
.description_ing h5 {
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 18px;
    text-transform: uppercase; 
    text-decoration: none;
    color: #080707;
}

.btn_ing {
    /* position: absolute;
    margin-top: 31%!important;
    left: 61%; */
    background-color:#fa0f0c;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
    width: 200px;
    
  }
  
  .btn_ing:hover {
      opacity: 1;
     
}

@media (max-width : 767px) {
    .bg_entete .slogan_ing {
        position: relative;
        width: 200px;
        padding: 20px;
        left: 0%;
        top: -53%;
        font-size: 35px;
    }
    .trait_deco {
        background-color: #fa0f0c;
        width: 3px;
        height: 420px;
        margin-left: 3%;
    }
    .entete_ing p {
        width: 90%;
        
        
    }
    .image_ing img {
        width: 90%;
        height: 200px;
        margin: 15px 0% 10px 30%;
        position: relative;
        left: -25%;
    }
    .txt_ing {
       
        width: 100%;
    }

}
* {
    box-sizing: border-box;
}
.calo {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
}
.one1,
 .one2,
  .one3,
   .one4,
    .one5,
     .two {

    position: absolute;
    width:90%;
    height: 85%;
    margin-right:5%;
    margin-left: 5%;
    margin-top: 0%;
    margin-bottom: 0%;
    z-index: 1;
    background-position: center;
}

.one1 {
    
    background-image: url(/static/media/thermique.6996a333.jpg);
    
}
.one2 {
    
    background-image: url(/static/media/frigorifique.5601ab29.jpg);
   

}
.one3 {
   
    background-image: url(/static/media/calorifugeage.b2e55266.jpg);
   

}

.one4 {
    
    background-image: url(/static/media/acoustique.9f1ecee7.jpg);
   

}
.one5 {
    
    background-image: url(/static/media/Aéraulique.620b3d70.jpg);
   
}
.two {
   
    background-color: rgba(13, 18, 20, 0.541);
    z-index: 2;

   
   
}
.three {
    display: inline!important; 
    position: absolute;
    width: 500px;
    height: 30px;
    left: 33%;
    top: 20%;
    z-index: 3;
    color: white;
    font-family: 'Open Sans', sans-serif !important;
   font-size: 40px;
    text-transform: uppercase;
    color: #fa0f0c;
    font-weight: bold;
}
    .para-ing {
        position: absolute;
        float: left;
        width: 500px;
        height: 100px;
        left: 20%;
        top: 50%;
        z-index: 3;
        color: white;
        font-family: 'Open Sans', sans-serif !important;
       font-size: 16px;
        text-transform: uppercase;
        overflow: hidden;

    }

    /* =====child of ingennierie style=== */

    .txt_ing_child {
        position: relative;
        width: 100%;
        height: 700px;
        background-image: url(/static/media/bg-ing.2fef5a94.jpg);
        background-position: center;
        margin-top: -50px;
    }
    .descr_child {
        top:10%;
        position: absolute;
        line-height:200%;
        width: 70%;
        height: 100%;
        left:15%;
        font-size: 20px;
        font-family: 'Open Sans', sans-serif !important;
        color: rgb(1, 1, 49);
    }

    .img_ing_child {
        margin-top: 25px;
        margin-left: 10% ;
        margin-right: 10%;
    
    }
    .calo_child {
        position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;

    }
    .one1_child,
 .one2_child,
  .one3_child,
   .one4_child,
    .one5_child,
     .two_child 
      {

    position: absolute;
    width:100%;
    height: 100%;
    margin-right:0%;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 0%;
    z-index: 1;
    background-position: center;
}

.one1_child {
    
    background-image: url(/static/media/thermique.6996a333.jpg);
    
}
.one2_child {
    
    background-image: url(/static/media/frigorifique.5601ab29.jpg);
   

}
.one3_child {
   
    background-image: url(/static/media/calorifugeage.b2e55266.jpg);
   

}

.one4_child {
    
    background-image: url(/static/media/acoustique.9f1ecee7.jpg);
   

}
.one5_child {
    
    background-image: url(/static/media/Aéraulique.620b3d70.jpg);
   
}
.one_child_qualite {
    
    
    position: absolute;
    width:100%;
    height: 100%;
    margin-right:0%;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 0%;
    z-index: 1;
    background-position: center;
   
}
.two_child {
   
    background-color: rgba(13, 18, 20, 0.541);
    z-index: 2;

   
   
}
.three_child {
    display: inline!important; 
    position: absolute;
    width: 500px;
    height: 30px;
    left: 33%;
    top: 20%;
    z-index: 3;
    color: white;
    font-family: 'Open Sans', sans-serif !important;
   font-size: 40px;
    text-transform: uppercase;
    color: white;
}

    @media (max-width:991px) {

        .three {
            height: 30px;
            left: 25%;
            top: 20%;
            z-index: 3;
            color: white;
            font-family: 'Open Sans', sans-serif !important;
           font-size: 25px;
            text-transform: uppercase;
        }
            .para-ing {
                display: none;
    }
    .calo {
        position: relative;
        width: 100%;
        height: 300px;
        margin-bottom: 50px;
    }
    .plus {
        left: 20%;
        top: 70%;
    }
  
}

.fil {
  display: block;
  position:relative;
  z-index: 2;
  width: 100%;
 height: 570px;
 background-color: rgba(13, 18, 20, 0.103); 
 margin-top: -570px;
 
  

}

.imgslide3 {
  position: relative;
 display: block;
  width: 100%;
  height: 570px;
  
  
  
}
.prev,
 .next{
  z-index: 3;
}

.sologan-slide {
  position:relative;
  z-index: 3;
  font-family: sans-serif;
  background-color: #fa0f0c ;
  left: 52%;
  top: 70%;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold;
  font-family: sans-serif;

}

.txt_slogan_slide {
  position:relative;
  width: 600px;
  height: 150px;
  z-index: 4;
  left: 10%;
  top: 22%;
  font-weight: bold;
  font-size: 35px;
  font-family: 'century-gothic-regular', sans-serif!important;
  text-transform: uppercase;
  color: rgb(250, 250, 250);
  -webkit-animation: slide-up 2s;
          animation: slide-up 2s;
  text-shadow: 0 0 5px #080707;
}

@-webkit-keyframes slide-up {
    from {
        opacity: 0.2;
        /* line-height: 200px; */
        left:-20%;
    }
    to {
        opacity: 1;
        /* line-height: 50px; */
        left:10%;
    }

  

}

@keyframes slide-up {
    from {
        opacity: 0.2;
        /* line-height: 200px; */
        left:-20%;
    }
    to {
        opacity: 1;
        /* line-height: 50px; */
        left:10%;
    }

  

}

/* this class for a btn inside it tag <a> btn take the classname ==teslabtnSlide== */
.teslabtnSlide .link-button{
  text-decoration: none;
  color: white !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold !important;
  letter-spacing: 1.1px;
  z-index: 5;
      
  }
  .teslabtnSlide{
  border: 0;
  outline: 0 !important;
  background-color:#fa100ca9;
  min-width: 175px;
  border-radius: 30px;
  padding: 10px;
  position: relative;
  transition: all 1s ease-in-out;
  max-height: 56px;
  left: 13%;
  top: 34%;
  z-index: 1;
  }
  
  .teslabtnSlide::before {
  content: "";
  border: white 2px solid;
  width: 96%;
  height: 87%;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  transition:  opacity 0.33s  ease-in-out;
  z-index: -1;
  }
  
  .teslabtnSlide:hover:before{
  opacity: 1;
  }
  
  /* =================================== */
  @media (max-width : 767px) {
    .txt_slogan_slide {
      position:relative;
      width:70%;
      height: 150px;
     
      z-index: 4;
      left: 23%;
        top: 44%;
      font-size: 20px;
      border-radius: 40px;
      color: rgb(241, 232, 232);
    
    }
    .teslabtnSlide{
      border: 0;
      outline: 0 !important;
      background-color: #fa0f0c;
      min-width: 145px;
      border-radius: 30px;
      padding: 10px;
      position: relative;
      transition: all 1s ease-in-out;
      max-height: 56px;
      left: 40%;
      top: 45%;
      z-index: 1;
      }
      .fil {
        display: block;
        position:relative;
        z-index: 2;
        width: 100%;
       height: 470px;
       background-color: rgba(13, 18, 20, 0.103); 
       margin-top: -570px;
       
        
      
      }
      
      .imgslide3 {
        position: relative;
       display: block;
        width: 100%;
        height: 470px;
        
        
        
      }
  }
* {
    box-sizing: border-box;
  }
.root_qs {
    display: flex;
    background-color: rgb(240, 240, 240);
    background-image: url(/static/media/fond.104ce7fe.png);
    background-position: center; 
    padding: 20px;
    width: 100%;
    height: 600px;
}

.left_qs {
width:50%;
display: flex;
flex-direction: column;

}
.left_qs_top {
  height: 50%; 
  display: flex;
  flex-direction: column; 
  justify-content: flex-end;
  align-items: flex-end;
}
.left_nv_top {
  position: relative;
    left: -10%;
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-start;
}

.left_qs_bottom {
  height: 50%; 
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-end;
}
.left_nv_bottom {
  position: relative;
    left: -10%;
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-start;
}

.right_qs {
  width:50%;
  display: flex;
flex-direction: column;
}
.right_qs_top {
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: flex-end;
  align-items: flex-start;
}
.right_qs_bottom {
  height: 50%;
}
.service_item {
  padding: 20px 0 20px 100px;
  color: rgb(28, 28, 65);
  font-size: 25px;
}
.txt_qs {
  padding:10px;
  width: 500px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(90, 90, 90);
 

}

.slogan_qs {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
}
.title_qs {
  font-weight: bold;
  color: #fa0f0c;
  font-size: 20px;
}
.entete_qs {
  display: flex;
  padding-bottom: 3%;
  width: 500px;
  justify-content: space-between;
}
.title_slogan_qs {
  display: flex;
  flex-direction: column;
  width: 96%;
}
.bar-color-qs {
  background-color: #fa0f0c;
  width: 1%;
  height: 75%;
  
}

.teslabtn_qs .link-button_qs{
  text-decoration: none;
  color: white !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold !important;
  letter-spacing: 1.1px;
  z-index: 5;
      
  }
  .teslabtn_qs{
  border: 0;
  outline: 0 !important;
  background-color:#fa100ca9;
  min-width: 175px;
  border-radius: 30px;
  padding: 10px;
  position: relative;
  transition: all 1s ease-in-out;
  max-height: 56px;
  
  z-index: 1;
  }
  
  .teslabtn_qs::before {
  content: "";
  border: white 2px solid;
  width: 96%;
  height: 87%;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
  transition:  opacity 0.33s  ease-in-out;
  z-index: -1;
  }
  
  .teslabtn_qs:hover:before{
  opacity: 1;
  }
  .button_qs {
    padding-right: 100px;
  }

  @media (max-width : 767px) {
    .root_qs {
      display: flex;
      flex-direction: column;
      height: 1000px;
  }  
  .right_qs {
    width:100%;
  
  }
  .left_qs {
    width:100%;
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    }
    .entete_qs {
      padding-top: 5%;
      width: 100%;
      
    }
    .txt_qs {
      
      width: 100%;
     
    }
    .left_nv_bottom {
        left: 0%;
    }
    .left_nv_top {
      left: 0%;
    }
  }

.root_home_ing {

        background-image: url(/static/media/ingenierie.1d4d2e54.jpg);
        width: 100%;
        height: 500px;
    
}

.filtre_hi {
    position: relative;
    width: 100%;
   height: 100%;
    z-index: 2;
    background-color: rgba(8, 32, 43, 0.61); 
  }
  .entete_hi {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 25%;
      position: relative;
      left: 40%;
      top:10%;
      align-items: center;
      justify-content: center;
  }
  .secteur_hi .link {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    
    font-family: 'Open Sans', sans-serif !important;
    text-align: center;
  }

  .title_hi {
      font-size: 27px;
      font-weight: bold;
      color: #fa0f0c;
      text-transform: uppercase;
  }
  .desc_hi {
      font-size: 22px;
      color: white;
      font-weight: bold;
  }
  .activite {
      position: relative;
      top:50%;
      
      display: flex;
      width: 100%;
      flex-direction: row;
  }
  @media (max-width : 767px) {
    .activite {
        flex-direction: column; 
        position: relative;
      top:10%;
      left:40%;
    }  
    .desc_hi {
        font-size: 20px;
        color: white;
        font-weight: bold;
        width: 190px;
        text-align: center;
    } 
    .root_home_ing {

        background-image: url(/static/media/ingenierie.1d4d2e54.jpg);
        width: 100%;
       height: 800px;   
        }
.filtre_hi {
    position: relative;
    width: 100%;
   height: 100%;
    z-index: 2;
    background-color: rgba(8, 32, 43, 0.61); 
  }
  .thermique_hi,
  .calorifugeage_hi,
  .frigorifique_hi,
  .acoustique_hi,
  .aerolique_hi {
      padding: 10px;
  }
}
  .thermique_hi,
  .calorifugeage_hi,
  .frigorifique_hi,
  .acoustique_hi,
  .aerolique_hi {
      width:20%;
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

  }

* {
    box-sizing: border-box;
  }
  .bg4 {
     
      display: flex;
      background-image: url(/static/media/qhse.e7e14a05.jpg);
      width: 100%;
      height: 500px;
  }
  .filtre4 {
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(8, 32, 43, 0.418); 
  }

  .txt4 {
      
   
      margin-left: 20%;
      margin-top: 5%;
      color: white;
      overflow-x: auto;
      overflow-y: auto;
     
     
  }
  .link {
      display: inline;
      text-decoration: none;
      color: antiquewhite;
      font-family: 'Open Sans', sans-serif !important;

  }

  .link a {
      color:#fa0f0c;
      font-weight: bold;
      font-family: 'Open Sans', sans-serif !important;
  }

  @media (max-width : 767px) { 
    .link a {

    }
    .txt4 {
      
   
        margin-left: 2%;
        margin-top: 5%;
        color: white;
        overflow-x: auto;
        overflow-y: auto;
       
       
    }

   }
.root-realisation{
width: 100%;
padding: 20px;
background-image: url(/static/media/bg-ing.2fef5a94.jpg);
background-position: center;
}
.left {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    justify-content: flex-end;
    

}

.left .entete_realisation {
  
    display: block;
    width: 80%;
    margin: 10px 10px;
    top: 0;
    position: relative;
    left:15%;
   

}
@media (max-width : 767px) {
    .left .entete_realisation {
        width: 80%;
        margin: 5px 5px;
        left:0;
        top: 2.5%;
        padding-top:20px;
    
    }  
}

.left .entete_realisation .bar_realisation {
position: relative;    
background-color: #fa0f0c;
width: 5px;
height: 100px;

}

.left .entete_realisation .title_realisation {
    position: relative;
    top: -44%;
    left: 5%;
    font-size: 23px;
    font-weight: bold;
    color: #fa0f0c


}

.left .entete_realisation .slogan_realisation {
    top: -45%;
    position: relative;
    left: 3%;
    overflow: auto;
    font-size: 16px;
    font-weight: bold;
}


   

.btn_ralisation {
    position: relative;
    left: 30%;
    top: -40%;
    background-color:#fa0f0c;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
}

.btn_ralisation:hover {
    opacity: 1;
   
}
.realisation_left {
    position: relative;
    width: 90%;
    top: -4%;
    left:20%;

}
@media (max-width : 767px) {
    .realisation_left {
        position: relative;
        width: 100%;
        top: -11%;
        left:10%;
    
    }    
}
.bizerte_realisation {
    background-image: url("/static/media/ciment bizerte.18dc4008.jpg");
    background-position: center;

    width: 90%;
    height:600px;
    margin: 0 10px;
}
.overlay_realisation {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #090b6d5e;
    cursor: pointer;
}
.p_realisation {

    position: relative;
    top: -81px;
    z-index: 3;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    -webkit-text-decoration: solid;
            text-decoration: solid;
   
    color: #f8f9fa;
    border: solid 4px #fa0f0c;
}

.sousse_realisation {
    background-image: url("/static/media/centrale sousse.a2d7a32f.jpg");
    background-position: center;

    width: 90%;
    height:400px;
    margin: 0 10px;
}
.right_realisation_1 {
    position: relative;
    width: 90%;
    left:0;
}

.right_realisation_2 {
    position: relative;
    width: 90%;
    left:0;
    margin-top: 20px;
}
@media (max-width : 767px) {
    .right_realisation_2,
    .right_realisation_1 {
        position: relative;
        width: 100%;
        left:10%;
        top:-10%;
    } 
   
}

.zaghouane_realisation {
    background-image: url(/static/media/calorifugeage.b2e55266.jpg);
    background-position: center;

    width: 90%;
    height:400px;
    margin: 0 10px;
}


html {
scroll-behavior: smooth;
}


.caroussel{
    width: 100%;
    height: 450px;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(226, 211, 211);
}
#imgcar{
    
    height: 300px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;

}

#prjtitle{
    color:#fa0f0c;
}
#card{
    width: 320px;
    border-radius: 20px;
}
#localisation{
    width: 15px;
    margin-bottom: -20px;
}
.ele4{
    width: 100%;
    height: 500px;
    background-image: url(/static/media/image4.6e945399.jpg);
}
#filtre4{
    position: relative;
    width: 100%;
    height: 500px;
    z-index: 1;

}
.QHSETXT{
position: relative;
 color: white;
 margin-top:-350px ;
 margin-left: 250px;
 z-index: 9;

}
#detail2{
    margin-left: 140px;
    margin-top: 50px;
    font-size: large;
    color: #fa0f0c;
}
.fleche3
{
    width: 20px;
    height: 10px;
    margin-top: 14px;
}
*{
    box-sizing: border-box;
}
.root_global_projet {
    background-image: url(/static/media/bg-ing.2fef5a94.jpg);
    padding: 20px;
  
}

/* entete titre slogan */
/* .titre_page_projet {
    position: absolute;
    font-weight: bolder;
    font-size: 28px;
    font-family: sans-serif;
    color: #fa0f0c;
    left: 6%;
    top: 17%;

}
.slogan_page_projet {
   
    position: absolute;
    font-size: 20px;
    color: rgb(2, 2, 36);
    font-weight: bold;
    width: 40%;
    left: 6%;
    top: 22%;
}
.bar_deco {
    position: absolute;
    
    width: 15px;
    height: 154px;
   
    top: 11%;
    left: 3%;
  background-color: #fa0f0c; */

  /* ============================= */
/* } */
.diaporama{
    position: relative;
    top:250px;
    width: 80%;
    left: 10%;
}
.p1  {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1010px;
    justify-content: space-between!important;
    align-items: flex-end;
    justify-content: center;
    
    
    /* align-items: center; */
    /* justify-content: start; */
    /* align-items: end;     */

}
.bizerte {
  background-image: url("/static/media/ciment bizerte.18dc4008.jpg");
  background-position: center;
  width: 98%;
    height:600px;
    
}
.overlay {
  
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #090b6d5e;
    cursor: pointer;
  }
  .bizerte:hover .overlay {
    opacity: 1;
  }
  .polycoq {
    background-image: url(/static/media/3.0480bca6.jpg);
    background-position: center;
    width: 98%;
    height:400px;
    
}
.polycoq:hover .overlay {
    opacity: 1;
  }

  .p2  {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: 1110px;
    align-items: center;
    
   

}
  .sousse {
    background-image: url(/static/media/sousse.d919e848.jpg);
    background-position: center;
    width: 100%;
    height: 500px;
}





  .sousse:hover .overlay {
    opacity: 1;
  }
  .jbaloust {
    background-image: url(/static/media/2.f67cf6c1.jpg);
    background-position: center;
    width: 100%;
    height: 600px;
}
.jbaloust:hover .overlay {
    opacity: 1;
  }

  .p3  {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1110px;
    justify-content: space-between!important;
    align-items: flex-start;
    justify-content: center;
  

}
  .rades {
    background-image: url("/static/media/Centrale electrique rades.39b5f011.jpg");
    background-position: center;
    width: 98%;
    height: 700px;
}

  .rades:hover .overlay {
    opacity: 1;
  }

  .matep {
    background-image: url(/static/media/2.f67cf6c1.jpg);
    background-position: center;
    width: 98%;
    height: 400px;
}

  .matep:hover .overlay {
    opacity: 1;
  }
  .p {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width : 767px) {
  .slogan_page_projet {
    font-size: 20px;
    color: rgb(2, 2, 36);
    font-weight: bold;
    width: 78%;
    left: 13%;
    top: 16.5%;
}
.titre_page_projet {
  font-weight: bolder;
  font-size: 28px;
  font-family: sans-serif;
  color: #fa0f0c;
  left: 12.5%;
  top: 12.5%;
}
.bar_deco {
  position: relative;
  width: 15px;
  height: 154px;
  top: 0%;
  left: 3%;
  background-color: #fa0f0c;
}
.diaporama{
  position: relative;
  top: 0px;
  width: 80%;
  left: 10%;
 
}
.root_global_projet {
  background-image: url(/static/media/bg-ing.2fef5a94.jpg);
padding: 20px;
}

}
.rect{

height: 75px;
width:40px;
margin-left:-35px;
}
.menu_style {
    position: sticky;
    top:-17px;
    z-index: 7;
    box-shadow: 1px 2px 10px #88888863;
    border: 5px;
}
.logo{
width: 120px;
padding-left: 20px;
}
nav {
    width:100%;
    padding: 20px;  
  
}



.title_rubrique {
  
    border: none;
    color: #1A4086;
    padding: 12px 22px;
    text-align: center;
    font-weight: bold;
    /* text-shadow: 0 0 1px; */
    font-size: 16px;
    margin: 4px 2px;
    opacity: 1;
    transition: 0.3s;
    /* border-radius: 15%; */
  }
  
  .title_rubrique:hover {
      opacity: 1;
      background-color:#fa100c7e;
      text-decoration: underline;
      /* border-radius: 14%; */
     
}

.aaa {
    margin-left: -10px;
    margin-top: 20px;
}
.rubrique_ing {
    margin-left: 0px;
    margin-top: 0px;
    cursor: pointer;
}


@media (max-width : 767px) {
nav {
    width:100%
}
.menu_style {
   
    position: relative;
    top:0px;
    z-index: 7;
    box-shadow: 0 0 10px #88888863;
    border: 5px;
}
}





* {
    box-sizing: border-box;
}
footer a {

    color:rgb(184, 174, 161);
    text-decoration: none;
    background-color: transparent;
}
footer{
    background-color: rgb(2, 2, 131);
    height: 400px;
    width: 100%;
}
.p_footerLink{
    color: rgb(216, 214, 214);
    margin-top: 15px;
    /* margin-left: 20px; */
}

.p_footerLink:hover{
    color: white;
}

#navlink{
    /* margin-left: 25px; */
}
.title{
    color: azure;
    margin-top: 25px;
    /* margin-left: 25px; */
}
#navlink_empty{
    /* margin-left:-25px; */
    padding: 0;

}
.imgFB{
    margin-top: 50px;
}
.imgldn
{
    margin-top: 20px;
}

#copyright{
    margin-top: 80px;
}
.logo-f{
    width: 30px;
}



    @media (max-width : 767px) {
        footer{
            background-color: rgb(2, 2, 131);
            height: 600px;
            width: 100%;
        }

    }

*{
    box-sizing: border-box;
}
.banner_th {
background-image: url(/static/media/thermique.6996a333.jpg);
background-position: center;
width: 100%;
height: 300px;
}
.title_rubrique_thermique {
    position: absolute;
    left: 10%;
    top: 30%;
    color: white;
    text-transform: uppercase;
    font-size: 45px !important;
    font-weight: bold;
    -webkit-animation: slide-up 3s;
            animation: slide-up 3s;
    text-shadow: 0 0 5px #080707;
}
  
  @-webkit-keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:10%;
      }
  }
  
  @keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:10%;
      }
  }
.root_th {
background-image: url(/static/media/bg-ing.2fef5a94.jpg);
width: 100%;
padding-top: 30px;
padding-bottom: 150px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center
}
.txt_box_th {
    width: 70%;
    padding: 50px;
    margin: auto;
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: rgba(128, 128, 128, 0.151);
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.493);

}
.root_th p {
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 18px;
}
.title_th {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Hind Siliguri', sans-serif;
    text-transform: uppercase;
}
.title_th:hover {
    text-decoration: underline;
}
 @media (max-width: 767px) {
    .root_th p {
        font-family: 'Hind Siliguri', sans-serif;
        font-size: 18px;
        width: 140%;
    }
    .title_th {
        width: 150%;
        
    }
    .title_rubrique_thermique {
        position: relative;
        left: 0;
     
        font-size: 40px !important;
   
    }
 }

.root_home_da {

  background-image: url(/static/media/image4.6e945399.jpg);
    width: 100%;
    height: 500px;

}



.filtre_da {
position: relative;
width: 100%;
height: 100%;
z-index: 2;
background-color: rgba(8, 32, 43, 0.61); 
}
.entete_da {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 25%;
  position: relative;
  left: 40%;
  top:10%;
  align-items: center;
  justify-content: center;
}
.secteur_da {
color: white;
font-weight: bold;
text-transform: uppercase;

font-family: 'Open Sans', sans-serif !important;
text-align: center;
}
.desc_secteur_da {
width: 120px;
color: white;
font-weight: bold;
}

.title1_da {
  font-size: 27px;
  font-weight: bold;
  color: #fa0f0c;
  text-transform: uppercase;
}
@media (max-width : 767px) {

  .title1_da {
    left: 7%;
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    position: relative;
    width: 400%;
    color: #fa0f0c;
}
}
.desc_da {
  font-size: 22px;
  color: white;
  font-weight: bold;
  width: 610px;
  
}
.activite_da {
  position: relative;
  top:20%;
  
  display: flex;
  width: 100%;
  flex-direction: row;
}
@media (max-width : 767px) {
.activite_da {
    flex-direction: column; 
    position: relative;
  top:10%;
  left:40%;
}  
.desc_da {
  font-size: 19px;
  color: white;
  width: 298px;
  text-align: center;
} 
.desc_secteur_da {
  width: auto;
  color: white;
  font-weight: bold;
}
.root_home_da {

  background-image: url(/static/media/image4.6e945399.jpg);
    width: 100%;
   height: 1150px;   
    }
.filtre_da {
position: relative;
width: 100%;
height: 100%;
z-index: 2;
background-color: rgba(8, 32, 43, 0.61); 
}
.energie_da,
.agroalimentaire,
.batiments,
.industries
 {
  padding: 20px;
}
}
.energie_da,
.agroalimentaire_da,
.batiments_da,
.industries_da {
  width:25%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}

* {
    box-sizing: border-box;
    font-family: 'Hind Siliguri', sans-serif;
}
.bannerqn {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 0px;
}
.oneqn,
.twoqn {

    position: absolute;
    width:100%;
    height: 85%;
    margin-right:0%;
    margin-left: 0%;
    margin-top: 0%;
  
    z-index: 1;
    background-position: center;
    
}

.oneqn {
    
    background-image: url(/static/media/image_slide1.5b1b74c7.png);
    
}


.twoqn {
   
    background-color: rgba(13, 18, 20, 0.541);
    z-index: 2;

   
   
}
.threeqn { 
    position: absolute;
    width: 50%;
    height: 30px;
    left: 10%;
    top: 40%;
    z-index: 3;
    color: white;
    font-family: 'Hind Siliguri', sans-serif;
   font-size: 55px;
    text-transform: uppercase;
    font-size: 55px;
    -webkit-animation: slide-up 3s;
            animation: slide-up 3s;
    text-shadow: 0 0 5px #080707;
  
  }
  @-webkit-keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:1%;
      }
  }
  @keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:1%;
      }
  }

.iconqn { 
    position: absolute;
    left: 45%;
    top: 20%;
    z-index: 3;
    color: white;
    font-size: 100px;
   
   
}
.para_qn {
    position: relative;
    width: 100%;
    height: 1000px;
   
    margin-top: -125px;
    z-index: 1;
}
.form_color{
    position: absolute;
    width: 235px;
    height: 191px;
    background-color: #fa0f0c;
   left: 50px;
   top:100px;
    z-index: 2;
}

.text-qn {
    position:absolute;
    width: 76%;
    left:12%;
    top:450px;

}

.image_qn {
    position: absolute;
    width: 300px;
    height: 200px;
    left:100px;
    z-index: 3;
   
}
.forme_img {
    width: 300px;
    height: 250px;
    left:100px;
    z-index: 3;
}
.img_text{
    top:300px;
}
.text-entete {
    left: 150px;
    top: 90px;
   
}
.titre_org {
    left: 150px;
    font-size: 18px;
    font-weight: bolder;
    color: #fa0f0c;
    top: 90px;
}

.titre_slogan {
    font-size: 24px;
    text-transform: uppercase;
    left: 150px;
    font-weight: bold;
    top: 90px;
}
.element_1 {
    background-color: #ebd9d956;
    margin-top: -75px;
    height: 550px;
}
.small_slide {

    top: 68px;
    left: 10px;
}
.bar-color {
    position: absolute;
    width: 22px;
    height: 111px;
    background-color: #fa0f0c;
    left: -11px;
    top: 7px;
    z-index: 2;
    
    height: 87px;
}



/* pourqoui nou choisir */

.pourquoinouschoisir {
    position: relative;
    width: 100%;
     height: 800px;
    
}

.pourquoinouschoisir .title {
    position: absolute;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: bold;
    font-size: 25px;
    padding: 20px;
    color: black;
    left: 20%;
    top: -4%;

}
.descr {
    position: absolute;
    top: 5%;
    left: 22%;
    font-family: 'Hind Siliguri', sans-serif;
    width: 60%;
}

.nosvaleurs {
    position: absolute;
    top:15%
}
.form_color_3 {
    position: absolute;
    left:20%;
    top:0px;
    width: 2px;
    height: 60px;
    background-color: #fa0f0c;
    z-index: 2;
}

.nosvaleurs1 {
    left:40%;
    width: 300px;
    overflow-wrap: normal;    display: flex;
    flex-direction: column;
  
}
.nosvaleurs2 {
    left: 60%;
    display: flex;
    flex-direction: column;

}

/* ======Expertise Style =======================*/

.expertise {
    position: relative;
    background-color: #ebd9d956;
    height: 550px;
}

.form_color_2{
    position: absolute;
    width: 235px;
    height: 191px;
    background-color: #fa0f0c;
   left: 50px;
   top:100px;
    z-index: 2;
}

.text-qn_2 {
    position:absolute;
    width: 76%;
    left:12%;
    top:450px;

}

.image_qn_2 {
    position: absolute;
    width: 300px;
    height: 200px;
    left:100px;
  
    z-index: 3;
   
}
.forme_img_2 {
    width: 300px;
    height: 250px;
    left:100px;
    z-index: 3;
}
.img_text_2{
    top:0px;
    font-family: 'Hind Siliguri', sans-serif;
}
.text-entete_2 {
    left: 150px;
    top: 90px;
    font-family: 'Hind Siliguri', sans-serif;
   
}
.titre_org_2 {
    left: 150px;
    font-size: 18px;
    font-weight: bolder;
    color: #fa0f0c;
    top: 90px;
}

.titre_slogan_2 {
    font-size: 24px;
    font-family: 'Hind Siliguri', sans-serif;
    text-transform: uppercase;
    left: 150px;
    font-weight: bold;
    top: 90px;
}
.form_color_2_2 {
    
    position: absolute;
    width: 22px;
    height: 114px;
    background-color: #fa0f0c;
    left: -15px;
    top: 7px;
    z-index: 2;

}

/* domaine d'application style */



.icon {
    color: #fa0f0c;
    font-size: 45px;
    align-items: center;
}


.desc_secteur {
    position: relative;
    color: white;
    /* margin-left: -40px; */
    font-family: 'Hind Siliguri', sans-serif;
    left: -20%;
    text-align: center;
    width: 50%;
}

@media (max-width : 767px) {
    .element_1 {
        background-color: #ebd9d956;
        margin-top: -75px;
        height: 970px;
    }

    .small_slide {
        left: 50px;
    }
    .text-entete {
        left: 7px;
        top: 90px;
    }
    .titre_slogan {
        font-size: 14px;
        text-transform: uppercase;
        left: 45px;
        font-weight: bold;
        top: 4px;
    }
    .titre_org {
           left: 49px;

        font-size: 15px;
        font-weight: bolder;
        color: #fa0f0c;
        top: 4px;
    }
    .bar-color {
        position: absolute;
        width: 22px;
        height: 111px;
        background-color: #fa0f0c;
        left: -15px;
        top: -2px;
        z-index: 2;
    }
    .bannerqn {
        position: relative;
        width: 100%;
        height: 371px;
        margin-bottom: 0px;
    }
    .oneqn, .twoqn {
        position: absolute;
        width: 100%;
        height: 80%;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 0%;
        z-index: 1;
        background-position: left;
    }
    .forme_img {
        width: 200px;
        height: 250px;
        margin-left: -100px;
        z-index: 3;
    }
    .image_qn {
        position: absolute;
        width: 300px;
        height: 200px;
        left: 139px;
        top:70px;
        z-index: 3;
    }
    .form_color {
        position: absolute;
        width: 153px;
        height: 191px;
        background-color: #fa0f0c;
        left: 12px;
        top: 160px;
        z-index: 2;
    }
    .descr {
        position: absolute;
        top: 7%;
        left: 13%;
        width: 350px;
    }
    .pourquoinouschoisir {
        position: relative;
        width: 100%;
        height: 1900px;
        margin-bottom: -200px;
    }
    .pourquoinouschoisir .title {
        position: absolute;
        font-weight: bold;
        font-size: 25px;
        padding: 20px;
        color: black;
        left: 15%;
        top: -1%;
    }
    .nosvaleurs1 {
        left: 3%;
        width: 300px;
        overflow-wrap: normal;
        top: -162px;
    }
    .nosvaleurs2 {
        left: 4%;
        top: -142px;
    }
    .expertise {
        position: relative;
        background-color: #ebd9d956;
        height: 735px;

    }
    .titre_org_2 {
        left: 44px;
        font-size: 18px;
        font-weight: bolder;
        color: #fa0f0c;
        top: 18px;
    }
    .form_color_2_2 {
        position: absolute;
        width: 22px;
        height: 114px;
        background-color: #fa0f0c;
        left: -15px;
        top: -18px;
        z-index: 2;
    }
    .titre_slogan_2 {
        font-size: 24px;
        text-transform: uppercase;
        left: 49px;
        font-weight: bold;
        top: 16px;
    }
   
    .text-entete_2 {
        left: 10px;
        top: 90px;
    }
    

.forme_img_2 {
    display: none;
    
}
.form_color_2 {
    display: none;
    
}

.image_qn_2 {
    display: none;
}
.title_da {
    left: -14%;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    position: relative;

}
.slogan_da {
    position: relative;
    color: white;
    font-family: sans-serif;
    font-size: 18px;
    left: -46%;
    width: 140%;
}
.da {
    height: 840px;
}

.energie {
left: 40px;
}

.activity {
    position: relative;
    top: 42%;
    left: 10%;
}
.threeqn {
    position: absolute;
    width: 400px;
    height: 30px;
    left: 22%;
    top: 48%;
    z-index: 3;
    color: white;
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: bold;
    border: 2px, black;
}



}
    

*{
   
    box-sizing: border-box;
}
.nosrealisations {
    display: flex;
    flex-direction: column;
}
.entete_nr {
    display: flex;
    left: 13%;
    position: relative;  
    -webkit-animation: slide-up 2s;  
            animation: slide-up 2s;
    
}
@-webkit-keyframes slide-up {
    from {
        opacity: 0.2;
        /* line-height: 200px; */
        left:-20%;
    }
    to {
        opacity: 1;
        /* line-height: 50px; */
        left:13%;
    }
}
@keyframes slide-up {
    from {
        opacity: 0.2;
        /* line-height: 200px; */
        left:-20%;
    }
    to {
        opacity: 1;
        /* line-height: 50px; */
        left:13%;
    }
}

/* entete titre slogan */
.titre_page_projet {
    position: relative;
    font-weight: bolder;
    font-size: 28px;
    font-family: sans-serif;
    color: #fa0f0c;
    left: 6%;
    top: 3%;

}
.slogan_page_projet {
   
    position: relative;
    font-size: 20px;
    color: rgb(2, 2, 36);
    font-weight: bold;
    width: 51%;
    left: 6%;
    top: 5%;
}
.bar_deco {
    position:relative;
    
    width: 15px;
    height: 110px;
   
    top: 11%;
    left: 3%;
  background-color: #fa0f0c;
}
  /* ============================= */
.root_nr
{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url(/static/media/bg-ing.2fef5a94.jpg);
    flex-wrap: wrap;
    padding-left: 15%;
    padding-right: 15%;

   
  

}

.realisation {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;

}

.realisation .card 
{
    position: relative;
    width: 320px;
    height: 420px ;
   
}
.realisation .card .imgBx
{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}

.realisation .card .imgBx img
{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.realisation .card .content
{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.realisation .card .content div

{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    color:#ffff;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    text-shadow: 2px 2px #080707;
   
}
.realisation .card:hover .content div
{
    visibility:visible;
    opacity: 1;
    -webkit-transform: translateY(50px);
            transform: translateY(50px);
}
.overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #fa100c57;
    cursor: pointer;
    visibility: hidden;
  }
  .realisation .card:hover .overlay {
    visibility:visible;
    opacity: 1;
      
  }
  @media (max-width : 1000px)
  {
  .realisation {
    width: calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
    padding-bottom: 20px;

}
  }

  @media (max-width : 800px)
  {
  .realisation {
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
    padding-bottom: 20px;

}
  }
