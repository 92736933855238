.root_projet {
    position: relative;
    height: 1000px;
    background-image: url("./../../../Assets/bg-ing.jpg");
    background-position: center;
}
.btn {
    position: absolute;
    margin-top: 31%!important;
    left: 61%;
    background-color:#fa0f0c;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
  }
  
  .btn:hover {
      opacity: 1;
     
}

  .title_project {
      position: absolute;
      margin-top: 7%;
      left: 65%;
      font-weight: bolder;
      font-size: 28px;
      font-family: sans-serif;
      color: #fa0f0c;

  }
  .slogan_project {
      position: absolute;
      margin-top: 16%;
      left: 10%;
      font-size: 20px;
      color:rgb(2, 2, 36);
      font-weight: bold;
  }
  .bar-color-projet {
    left: 7%; 
    margin-top: 5%;
    position:absolute;
    width: 15px;
    height: 50px;
    background-color: #fa0f0c;
    height: 145px;
    /* animation: mymove 5s infinite; */
}
/* @keyframes mymove {
  from {left: 0px;}
  to {left: 200px;}
} */
  /* Projet 1 */
  .img_project1 {
    position: absolute;
    margin-top: 44%;
    left: 16%;
    width: 80%;
    height: 570px;
    display: block;
    transition: .5s ease;
    backface-visibility: hidden;
    cursor: pointer;
 
  }
/* overlay effect */
.overlay1 {
  position: absolute;
  margin-top: 44%;
  left: 16%;
  width: 80%;
  height: 570px;
  opacity: 0;
  transition: .5s ease;
  background-color: #090b6d5e;
  cursor: pointer;
}

.projet_1:hover .overlay1 {
  opacity: 1;
}
.title_project_child1 {
    position: absolute;
    z-index: 6;
    text-transform: uppercase;
    font-size: 34px;
    font-weight: bold;
    left: 21%;
    margin-top: 90%;
    color: white;
}
.bar-color-projet_2 {
    left: 0%;
    margin-top: 12%;
    position: absolute;
    width: 91px;
    height: 5px;
    background-color: #fa0f0c;
}




/* ===== */

/* projet2 */
.img_project2 {
  position: absolute;
  margin-top: 90px;
  left: 2%;
  width: 80%;
  height: 400px;
  cursor: pointer;
}
/* overlay effect */
.overlay2 {
  position: absolute;
  margin-top: 90px;
  left: 2%;
  width: 80%;
  height: 400px;
  opacity: 0;
  transition: .5s ease;
  background-color: #090b6d5e;
  cursor: pointer;
}

.projet_2:hover .overlay2 {
  opacity: 1;
}
.title_project_child2 {
        color: white;
        font-size: 29px;
        position: absolute;
        margin-top: 57%;
        left: 36%;
        transform: translate(-50%, -50%);
        text-align: center;
        z-index: 6;
        font-weight: bold;
        text-transform: uppercase;
    }
/* ============================================= */

/* projet2 */
.img_project3 {
  position: absolute;
  margin-top: 517px;
  left: 2%;
  width: 80%;
  height: 400px;
  cursor: pointer;
}
/* overlay effect */
.overlay3 {
  position: absolute;
  margin-top: 517px;
  left: 2%;
  width: 80%;
  height: 400px;
  opacity: 0;
  transition: .5s ease;
  background-color: #090b6d5e;
  cursor: pointer;
}

.projet_3:hover .overlay3 {
  opacity: 1;
}
.title_project_child3 {
    color: white;
    position: absolute;
    margin-top: 110%;
    left: 36%;
    transform: translate(-50%, -50%);
    text-align: center;
    z-index: 6;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: bold;
}
/* ============================================= */

@media (max-width : 767px) {

    .root_projet {
        position: relative;
        height: 1500px;
        background-image: url("./../../../Assets/bg-ing.jpg");
        background-position: center;
    }
    .btn {
        position: absolute;
        margin-top: 49%!important;
        left: 37%;
        background-color:#fa0f0c;
        border: none;
        color: white;
        padding: 16px 32px;
        text-align: center;
        font-size: 16px;
        margin: 4px 2px;
        opacity: 0.6;
        transition: 0.3s;
      }
      
      .btn:hover {
          opacity: 1;
         
    }
    
      .title_project {
          position: absolute;
          margin-top: 7%;
          left: 15%;
          font-weight: bolder;
          font-size: 28px;
          font-family: sans-serif;
          color: #fa0f0c;
    
      }
      .slogan_project {
          position: absolute;
          margin-top: 16%;
          left: 15%;
          font-size: 20px;
          color:rgb(2, 2, 36);
          font-weight: bold;
      }
      .bar-color-projet {
        left: 7%; 
        margin-top: 5%;
        position:absolute;
        width: 15px;
        height: 50px;
        background-color: #fa0f0c;
        height: 145px;
    }
      /* Projet 1 */
      .img_project1 {
        position: absolute;
        margin-top: 72%;
        left: 11%;
        width: 80%;
        height: 350px;
        display: block;
        transition: .5s ease;
        backface-visibility: hidden;
        cursor: pointer;
     
      }
    /* overlay effect */
    .overlay1 {
      position: absolute;
      margin-top: 72%;
      left: 11%;
      width: 80%;
      height: 350px;
      opacity: 0;
      transition: .5s ease;
      background-color: #0003ba5e;
      cursor: pointer;
    }
    
    .projet_1:hover .overlay1 {
      opacity: 1;
    }
    .title_project_child1 {
      color: white;
      font-size: 20px;
      position: absolute;
      top: 80%;
      left: 65%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
    
    
    /* ===== */
    
    /* projet2 */
    .img_project2 {
        position: absolute;
        margin-top: 653px;
        left: 11%;
        width: 80%;
        height: 350px;
        cursor: pointer;
    }
    /* overlay effect */
    .overlay2 {
      position: absolute;
      margin-top: 653px;
      left: 11%;
      width: 80%;
      height: 350px;
      opacity: 0;
      transition: .5s ease;
      background-color: #0003ba5e;
      cursor: pointer;
    }
    
    .projet_2:hover .overlay2 {
      opacity: 1;
    }
    .title_project_child2 {
      color: white;
      font-size: 20px;
      position: absolute;
      top: 90%;
      left: 65%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }
    /* ============================================= */
    
    /* projet2 */
    .img_project3 {
      position: absolute;
      margin-top: 1010px;
      left: 11%;
      width: 80%;
      height: 350px;
      cursor: pointer;
    }
    /* overlay effect */
    .overlay3 {
      position: absolute;
      margin-top: 1010px;
      left: 11%;
      width: 80%;
      height: 350px;
      opacity: 0;
      transition: .5s ease;
      background-color: #0003ba5e;
      cursor: pointer;
    }
    
    .projet_3:hover .overlay3 {
      opacity: 1;
    }
    .title_project_child3 {
      color: white;
      font-size: 20px;
      position: absolute;
      top: 90%;
      left: 65%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      text-align: center;
    }

} 
 

 
