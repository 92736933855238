.root-realisation{
width: 100%;
padding: 20px;
background-image: url('./../../../Assets/bg-ing.jpg');
background-position: center;
}
.left {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    justify-content: flex-end;
    

}

.left .entete_realisation {
  
    display: block;
    width: 80%;
    margin: 10px 10px;
    top: 0;
    position: relative;
    left:15%;
   

}
@media (max-width : 767px) {
    .left .entete_realisation {
        width: 80%;
        margin: 5px 5px;
        left:0;
        top: 2.5%;
        padding-top:20px;
    
    }  
}

.left .entete_realisation .bar_realisation {
position: relative;    
background-color: #fa0f0c;
width: 5px;
height: 100px;

}

.left .entete_realisation .title_realisation {
    position: relative;
    top: -44%;
    left: 5%;
    font-size: 23px;
    font-weight: bold;
    color: #fa0f0c


}

.left .entete_realisation .slogan_realisation {
    top: -45%;
    position: relative;
    left: 3%;
    overflow: auto;
    font-size: 16px;
    font-weight: bold;
}


   

.btn_ralisation {
    position: relative;
    left: 30%;
    top: -40%;
    background-color:#fa0f0c;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
}

.btn_ralisation:hover {
    opacity: 1;
   
}
.realisation_left {
    position: relative;
    width: 90%;
    top: -4%;
    left:20%;

}
@media (max-width : 767px) {
    .realisation_left {
        position: relative;
        width: 100%;
        top: -11%;
        left:10%;
    
    }    
}
.bizerte_realisation {
    background-image: url('./../../../Assets/ciment\ bizerte.jpg');
    background-position: center;

    width: 90%;
    height:600px;
    margin: 0 10px;
}
.overlay_realisation {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #090b6d5e;
    cursor: pointer;
}
.p_realisation {

    position: relative;
    top: -81px;
    z-index: 3;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    text-decoration: solid;
   
    color: #f8f9fa;
    border: solid 4px #fa0f0c;
}

.sousse_realisation {
    background-image: url('./../../../Assets/centrale\ sousse.jpg');
    background-position: center;

    width: 90%;
    height:400px;
    margin: 0 10px;
}
.right_realisation_1 {
    position: relative;
    width: 90%;
    left:0;
}

.right_realisation_2 {
    position: relative;
    width: 90%;
    left:0;
    margin-top: 20px;
}
@media (max-width : 767px) {
    .right_realisation_2,
    .right_realisation_1 {
        position: relative;
        width: 100%;
        left:10%;
        top:-10%;
    } 
   
}

.zaghouane_realisation {
    background-image: url('./../../../Assets/calorifugeage.jpg');
    background-position: center;

    width: 90%;
    height:400px;
    margin: 0 10px;
}

