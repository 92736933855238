.root_home_ing {

        background-image: url('./../../../Assets/ingenierie.jpg');
        width: 100%;
        height: 500px;
    
}

.filtre_hi {
    position: relative;
    width: 100%;
   height: 100%;
    z-index: 2;
    background-color: rgba(8, 32, 43, 0.61); 
  }
  .entete_hi {
      display: flex;
      flex-direction: column;
      padding: 10px;
      width: 25%;
      position: relative;
      left: 40%;
      top:10%;
      align-items: center;
      justify-content: center;
  }
  .secteur_hi .link {
    color: white;
    font-weight: bold;
    text-transform: uppercase;
    
    font-family: 'Open Sans', sans-serif !important;
    text-align: center;
  }

  .title_hi {
      font-size: 27px;
      font-weight: bold;
      color: #fa0f0c;
      text-transform: uppercase;
  }
  .desc_hi {
      font-size: 22px;
      color: white;
      font-weight: bold;
  }
  .activite {
      position: relative;
      top:50%;
      
      display: flex;
      width: 100%;
      flex-direction: row;
  }
  @media (max-width : 767px) {
    .activite {
        flex-direction: column; 
        position: relative;
      top:10%;
      left:40%;
    }  
    .desc_hi {
        font-size: 20px;
        color: white;
        font-weight: bold;
        width: 190px;
        text-align: center;
    } 
    .root_home_ing {

        background-image: url('./../../../Assets/ingenierie.jpg');
        width: 100%;
       height: 800px;   
        }
.filtre_hi {
    position: relative;
    width: 100%;
   height: 100%;
    z-index: 2;
    background-color: rgba(8, 32, 43, 0.61); 
  }
  .thermique_hi,
  .calorifugeage_hi,
  .frigorifique_hi,
  .acoustique_hi,
  .aerolique_hi {
      padding: 10px;
  }
}
  .thermique_hi,
  .calorifugeage_hi,
  .frigorifique_hi,
  .acoustique_hi,
  .aerolique_hi {
      width:20%;
      display:flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

  }