.site{
    min-height:100vh;
    margin-bottom: -250px;

}
footer, .minsite{
    height:250px;
}

@media only screen and (max-width:720px){
    .site{
        min-height:100vh;
        margin-bottom: -400px;
    }
        footer, .minsite{
            height:400px;
        }
    }