.rect{

height: 75px;
width:40px;
margin-left:-35px;
}
.menu_style {
    position: sticky;
    top:-17px;
    z-index: 7;
    box-shadow: 1px 2px 10px #88888863;
    border: 5px;
}
.logo{
width: 120px;
padding-left: 20px;
}
nav {
    width:100%;
    padding: 20px;  
  
}



.title_rubrique {
  
    border: none;
    color: #1A4086;
    padding: 12px 22px;
    text-align: center;
    font-weight: bold;
    /* text-shadow: 0 0 1px; */
    font-size: 16px;
    margin: 4px 2px;
    opacity: 1;
    transition: 0.3s;
    /* border-radius: 15%; */
  }
  
  .title_rubrique:hover {
      opacity: 1;
      background-color:#fa100c7e;
      text-decoration: underline;
      /* border-radius: 14%; */
     
}

.aaa {
    margin-left: -10px;
    margin-top: 20px;
}
.rubrique_ing {
    margin-left: 0px;
    margin-top: 0px;
    cursor: pointer;
}


@media (max-width : 767px) {
nav {
    width:100%
}
.menu_style {
   
    position: relative;
    top:0px;
    z-index: 7;
    box-shadow: 0 0 10px #88888863;
    border: 5px;
}
}




