* {
    box-sizing: border-box;
}
.scalo {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
}
.sone1,
 .sone2,
  .sone3,
   .sone4,
    .sone5,
     .stwo {

    position: absolute;
    width:80%;
    height: 85%;
    margin-right:10%;
    margin-left: 10%;
    margin-top: 5%;
    margin-bottom: 0%;
    z-index: 1;
    background-position: center;
}

.sone1 {
    
    background-image: url("./../../Assets/etude.jpg");
    
}
.sone2 {
    
    background-image: url("./../../Assets/installation.jpg");
   

}
.sone3 {
   
    background-image: url("./../../Assets/maintenance.jpg");
   

}

.sone4 {
    
    background-image: url("./../../Assets/controle.jpg");
   

}

   

.stwo {
   
    background-color: rgba(13, 18, 20, 0.541);
    z-index: 2;

   
   
}
.sthree {
    position: absolute;
    width: 200px;
    height: 100px;
    left: 33%;
    top: 40%;
    z-index: 3;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    font-size:67px;
    text-transform: uppercase;
  

  
  
}

.plus {
    position: absolute;
    width: 240px;
    height: 50px;
    z-index: 3;
    font-family: sans-serif;
    font-weight: bolder;
    font-size: 22px;
    color: white;
    left: 58%;
    top: 80%;
    text-decoration: underline;

}

@media (max-width:991px) {

    .sthree {
     
        width: 500px;
        height: 30px;
        left: 25%;
        top: 20%;
        z-index: 3;
        color: white;
        font-family: Arial, Helvetica, sans-serif;
       font-size: 20px;
        text-transform: uppercase;
    }
       

.scalo {
    position: relative;
    width: 100%;
    height: 300px;
    margin-bottom: 50px;
}

}