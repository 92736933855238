@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap');
* {
    box-sizing: border-box;
    font-family: 'Hind Siliguri', sans-serif;
}
.bannerqn {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 0px;
}
.oneqn,
.twoqn {

    position: absolute;
    width:100%;
    height: 85%;
    margin-right:0%;
    margin-left: 0%;
    margin-top: 0%;
  
    z-index: 1;
    background-position: center;
    
}

.oneqn {
    
    background-image: url("./../../Assets/image_slide1.png");
    
}


.twoqn {
   
    background-color: rgba(13, 18, 20, 0.541);
    z-index: 2;

   
   
}
.threeqn { 
    position: absolute;
    width: 50%;
    height: 30px;
    left: 10%;
    top: 40%;
    z-index: 3;
    color: white;
    font-family: 'Hind Siliguri', sans-serif;
   font-size: 55px;
    text-transform: uppercase;
    font-size: 55px;
    animation: slide-up 3s;
    text-shadow: 0 0 5px #080707;
  
  }
  @keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:1%;
      }
  }

.iconqn { 
    position: absolute;
    left: 45%;
    top: 20%;
    z-index: 3;
    color: white;
    font-size: 100px;
   
   
}
.para_qn {
    position: relative;
    width: 100%;
    height: 1000px;
   
    margin-top: -125px;
    z-index: 1;
}
.form_color{
    position: absolute;
    width: 235px;
    height: 191px;
    background-color: #fa0f0c;
   left: 50px;
   top:100px;
    z-index: 2;
}

.text-qn {
    position:absolute;
    width: 76%;
    left:12%;
    top:450px;

}

.image_qn {
    position: absolute;
    width: 300px;
    height: 200px;
    left:100px;
    z-index: 3;
   
}
.forme_img {
    width: 300px;
    height: 250px;
    left:100px;
    z-index: 3;
}
.img_text{
    top:300px;
}
.text-entete {
    left: 150px;
    top: 90px;
   
}
.titre_org {
    left: 150px;
    font-size: 18px;
    font-weight: bolder;
    color: #fa0f0c;
    top: 90px;
}

.titre_slogan {
    font-size: 24px;
    text-transform: uppercase;
    left: 150px;
    font-weight: bold;
    top: 90px;
}
.element_1 {
    background-color: #ebd9d956;
    margin-top: -75px;
    height: 550px;
}
.small_slide {

    top: 68px;
    left: 10px;
}
.bar-color {
    position: absolute;
    width: 22px;
    height: 111px;
    background-color: #fa0f0c;
    left: -11px;
    top: 7px;
    z-index: 2;
    
    height: 87px;
}



/* pourqoui nou choisir */

.pourquoinouschoisir {
    position: relative;
    width: 100%;
     height: 800px;
    
}

.pourquoinouschoisir .title {
    position: absolute;
    font-family: 'Hind Siliguri', sans-serif;
    font-weight: bold;
    font-size: 25px;
    padding: 20px;
    color: black;
    left: 20%;
    top: -4%;

}
.descr {
    position: absolute;
    top: 5%;
    left: 22%;
    font-family: 'Hind Siliguri', sans-serif;
    width: 60%;
}

.nosvaleurs {
    position: absolute;
    top:15%
}
.form_color_3 {
    position: absolute;
    left:20%;
    top:0px;
    width: 2px;
    height: 60px;
    background-color: #fa0f0c;
    z-index: 2;
}

.nosvaleurs1 {
    left:40%;
    width: 300px;
    overflow-wrap: normal;    display: flex;
    flex-direction: column;
  
}
.nosvaleurs2 {
    left: 60%;
    display: flex;
    flex-direction: column;

}

/* ======Expertise Style =======================*/

.expertise {
    position: relative;
    background-color: #ebd9d956;
    height: 550px;
}

.form_color_2{
    position: absolute;
    width: 235px;
    height: 191px;
    background-color: #fa0f0c;
   left: 50px;
   top:100px;
    z-index: 2;
}

.text-qn_2 {
    position:absolute;
    width: 76%;
    left:12%;
    top:450px;

}

.image_qn_2 {
    position: absolute;
    width: 300px;
    height: 200px;
    left:100px;
  
    z-index: 3;
   
}
.forme_img_2 {
    width: 300px;
    height: 250px;
    left:100px;
    z-index: 3;
}
.img_text_2{
    top:0px;
    font-family: 'Hind Siliguri', sans-serif;
}
.text-entete_2 {
    left: 150px;
    top: 90px;
    font-family: 'Hind Siliguri', sans-serif;
   
}
.titre_org_2 {
    left: 150px;
    font-size: 18px;
    font-weight: bolder;
    color: #fa0f0c;
    top: 90px;
}

.titre_slogan_2 {
    font-size: 24px;
    font-family: 'Hind Siliguri', sans-serif;
    text-transform: uppercase;
    left: 150px;
    font-weight: bold;
    top: 90px;
}
.form_color_2_2 {
    
    position: absolute;
    width: 22px;
    height: 114px;
    background-color: #fa0f0c;
    left: -15px;
    top: 7px;
    z-index: 2;

}

/* domaine d'application style */



.icon {
    color: #fa0f0c;
    font-size: 45px;
    align-items: center;
}


.desc_secteur {
    position: relative;
    color: white;
    /* margin-left: -40px; */
    font-family: 'Hind Siliguri', sans-serif;
    left: -20%;
    text-align: center;
    width: 50%;
}

@media (max-width : 767px) {
    .element_1 {
        background-color: #ebd9d956;
        margin-top: -75px;
        height: 970px;
    }

    .small_slide {
        left: 50px;
    }
    .text-entete {
        left: 7px;
        top: 90px;
    }
    .titre_slogan {
        font-size: 14px;
        text-transform: uppercase;
        left: 45px;
        font-weight: bold;
        top: 4px;
    }
    .titre_org {
           left: 49px;

        font-size: 15px;
        font-weight: bolder;
        color: #fa0f0c;
        top: 4px;
    }
    .bar-color {
        position: absolute;
        width: 22px;
        height: 111px;
        background-color: #fa0f0c;
        left: -15px;
        top: -2px;
        z-index: 2;
    }
    .bannerqn {
        position: relative;
        width: 100%;
        height: 371px;
        margin-bottom: 0px;
    }
    .oneqn, .twoqn {
        position: absolute;
        width: 100%;
        height: 80%;
        margin-right: 0%;
        margin-left: 0%;
        margin-top: 0%;
        z-index: 1;
        background-position: left;
    }
    .forme_img {
        width: 200px;
        height: 250px;
        margin-left: -100px;
        z-index: 3;
    }
    .image_qn {
        position: absolute;
        width: 300px;
        height: 200px;
        left: 139px;
        top:70px;
        z-index: 3;
    }
    .form_color {
        position: absolute;
        width: 153px;
        height: 191px;
        background-color: #fa0f0c;
        left: 12px;
        top: 160px;
        z-index: 2;
    }
    .descr {
        position: absolute;
        top: 7%;
        left: 13%;
        width: 350px;
    }
    .pourquoinouschoisir {
        position: relative;
        width: 100%;
        height: 1900px;
        margin-bottom: -200px;
    }
    .pourquoinouschoisir .title {
        position: absolute;
        font-weight: bold;
        font-size: 25px;
        padding: 20px;
        color: black;
        left: 15%;
        top: -1%;
    }
    .nosvaleurs1 {
        left: 3%;
        width: 300px;
        overflow-wrap: normal;
        top: -162px;
    }
    .nosvaleurs2 {
        left: 4%;
        top: -142px;
    }
    .expertise {
        position: relative;
        background-color: #ebd9d956;
        height: 735px;

    }
    .titre_org_2 {
        left: 44px;
        font-size: 18px;
        font-weight: bolder;
        color: #fa0f0c;
        top: 18px;
    }
    .form_color_2_2 {
        position: absolute;
        width: 22px;
        height: 114px;
        background-color: #fa0f0c;
        left: -15px;
        top: -18px;
        z-index: 2;
    }
    .titre_slogan_2 {
        font-size: 24px;
        text-transform: uppercase;
        left: 49px;
        font-weight: bold;
        top: 16px;
    }
   
    .text-entete_2 {
        left: 10px;
        top: 90px;
    }
    

.forme_img_2 {
    display: none;
    
}
.form_color_2 {
    display: none;
    
}

.image_qn_2 {
    display: none;
}
.title_da {
    left: -14%;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    position: relative;

}
.slogan_da {
    position: relative;
    color: white;
    font-family: sans-serif;
    font-size: 18px;
    left: -46%;
    width: 140%;
}
.da {
    height: 840px;
}

.energie {
left: 40px;
}

.activity {
    position: relative;
    top: 42%;
    left: 10%;
}
.threeqn {
    position: absolute;
    width: 400px;
    height: 30px;
    left: 22%;
    top: 48%;
    z-index: 3;
    color: white;
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 23px;
    text-transform: uppercase;
    font-weight: bold;
    border: 2px, black;
}



}
    
