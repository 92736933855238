*{
    box-sizing: border-box;
}
.root_contact{
    display: flex;
    width: 100%;
    height: 700px;
}
.left_contact,
.right_contact {
    width: 50%;
}
.right_contact iframe {
    width: 100%;
    height: 100%;
}
.left_contact {
    background-color: #1a40863d;
    flex-direction: column;
}
.left_top_contact {
    height: 50%;
    display: flex;
    flex-direction:column ;
}
.entete_contact {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: #9e9e9e54;
    border-radius: 10px;
    width: 80%;
    padding: 30px;
}

.title_contact {

    font-size: 35px;
    font-weight: bold;
    color: black;
    padding: 25px;
    position:relative;
    left:40%;
    text-transform: uppercase;

}
.trait_contact {
    top: -9%;
    background-color: #fa0f0c;
    width: 15%;
    height: 2px;
    position: relative;
    left: 43%;
}
.adr_contact {
    font-size: 20px;

}
.tél{
    font-size: 20px;
    font-weight: bold;
}
.num_tél {
    font-size: 20px;
}
.email {
    font-size: 20px;
    font-weight: bold;
}
.adr_email {
    font-size: 20px;
}
.form_contact {
    width: 100%;
    padding: 10px;
    height: 50%;

}
/* this class for a btn inside it tag <a> btn take the classname ==teslabtnSlide== */
.btn_contact .link-button{
    text-decoration: none;
    color: white !important;
    font-family: 'Open Sans', sans-serif !important;
    font-weight: bold !important;
    letter-spacing: 1.1px;
    z-index: 5;
        
    }
    .btn_contact{
    border: 0;
    outline: 0 !important;
    background-color:#fa100ca9;
    min-width: 175px;
    border-radius: 30px;
    
    position: relative;
    transition: all 1s ease-in-out;
    max-height: 56px;
    left: 40%;
    padding: 10px;
    z-index: 1;
    }
    
    .btn_contact::before {
    content: "";
    border: white 2px solid;
    width: 96%;
    height: 87%;
    border-radius: 30px;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%,-50%);
    -webkit-transition: opacity 0.33s  ease-in-out;
    -moz-transition: opacity 0.33s  ease-in-out;
    -ms-transition: opacity 0.33s  ease-in-out;
    -o-transition: opacity 0.33s  ease-in-out;
    transition:  opacity 0.33s  ease-in-out;
    z-index: -1;
    }
    
    .btn_contact:hover:before{
    opacity: 1;
    }
    
    /* =================================== */
    @media (max-width : 767px) {
        .root_contact{
            display: flex;
            flex-direction: column-reverse;
            height: 1100px;
        }
        .left_contact
        {   height: 70%;
            width: 100%;
}
.right_contact iframe {
    width: 100%;
  

}
.right_contact {
    display: flex;
    width: 100%;
    height:30%;
}
.title_contact {
   
    left: 23%;
    
}
.btn_contact {
  
    left: 24%;
   
}
    }