.map-container{
    overflow:hidden;
    padding-bottom:56.25%;
    position:relative;
    height:0;
    }
    .map-container iframe{
    margin-left:auto;
    margin-right: auto;
    top:0;
    height:100%;
    width:100%;
    position:relative;
    }