*{
    box-sizing: border-box;
}
.slide_qhse {
    display: flex;
    width: 100%;
    height: 400px;
    background-image: url('./../../Assets/qhse.jpg');
    background-position: center;
    justify-content: center;
    align-items: flex-start;
    
}

.filtre_qhse {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(8, 32, 43, 0.61); 
  }

  .title_rubrique_qhse {
      position: relative;
      left:1%;
      top: 40%;
      width: 50%;
      height: 100px; 
      color: white;
      font-size: 55px;
      animation: slide-up 3s;
      text-shadow: 0 0 5px #080707;
    
    }
    @keyframes slide-up {
        from {
            opacity: 0.2;
            /* line-height: 200px; */
            left:-20%;
        }
        to {
            opacity: 1;
            /* line-height: 50px; */
            left:1%;
        }
    }

  
.qhse_root {
    background-image: url('./../../Assets/bg-ing.jpg') ;
    display: flex;
    background-color: rgb(240, 240, 240);
    background-position: center; 
    padding: 20px;
    width: 100%;
    /* height: 600px; */
    padding: 25px;
}
.left_qhse {
    width: 50%;
    display: flex;
    flex-direction: column;
    /* justify-content: center;
    align-items: center; */
}
.left_top_qhse {
  
  display: flex;
  flex-direction: column; 
  height: 100%;
  justify-content: center; 
  align-items: center;

}
.entete_qhse_left {
    display: flex;
    padding:20px;
}
.etlaqualite {
    color: #1A4086;
    font-weight: bold;
    font-size: 30px;
    text-transform: uppercase;
}
.txt_qualite_qhse {
    font-size: 20px;
    color: white;
    width: 80%;
    background-color: #1a40868a;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.493);
}
.left_bottom_qhse {
    display: flex;
    flex-direction: column; 
    height: 0%;
    justify-content: center; 
    align-items: center;  
}
.right_qhse {
    width: 50%;
    display: flex;
    flex-direction: column;
}
.right_top_qhse {
    display: flex;
    flex-direction: column; 
    height: 100%;
    justify-content: center; 
    align-items: center;  
}
.right_bottom_qhse {
    display: flex;
    flex-direction: column; 
    height: 0%;
    justify-content: center; 
    align-items: center;  
}
.slogan_hse {
    color: #fa0f0c;
    font-weight: bold;
    text-transform: uppercase;
    
}

.puce2 {
  
    position: relative;
    left:90%;
}

.rochdi_fondateur {
    position: relative;
    left: 50%;
    padding-top: 25px;
    padding-bottom: 25px;
    color: rgb(16, 16, 44);
    
}

.rochdi {
    font-family: italic, cursive;
    font-weight: bold;
}

.resp_hse {
    position: relative;
    font-family: italic, cursive;
    left: 55%;
    font-weight: bold;
    color: rgb(16, 16, 44);
}
@media (max-width : 767px) {

    .qhse_root {
  
        flex-direction: column;
      
       
    }
    .left_qhse,
    .right_qhse  {
        width: 100%; }
        .rochdi_fondateur ,
        .resp_hse {
            left: 0%;    
        }
}




