*{
    box-sizing: border-box;
}
.root_global_projet {
    background-image: url('./../../Assets/bg-ing.jpg');
    padding: 20px;
  
}

/* entete titre slogan */
/* .titre_page_projet {
    position: absolute;
    font-weight: bolder;
    font-size: 28px;
    font-family: sans-serif;
    color: #fa0f0c;
    left: 6%;
    top: 17%;

}
.slogan_page_projet {
   
    position: absolute;
    font-size: 20px;
    color: rgb(2, 2, 36);
    font-weight: bold;
    width: 40%;
    left: 6%;
    top: 22%;
}
.bar_deco {
    position: absolute;
    
    width: 15px;
    height: 154px;
   
    top: 11%;
    left: 3%;
  background-color: #fa0f0c; */

  /* ============================= */
/* } */
.diaporama{
    position: relative;
    top:250px;
    width: 80%;
    left: 10%;
}
.p1  {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1010px;
    justify-content: space-between!important;
    align-items: flex-end;
    justify-content: center;
    
    
    /* align-items: center; */
    /* justify-content: start; */
    /* align-items: end;     */

}
.bizerte {
  background-image: url('./../../Assets/ciment\ bizerte.jpg');
  background-position: center;
  width: 98%;
    height:600px;
    
}
.overlay {
  
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #090b6d5e;
    cursor: pointer;
  }
  .bizerte:hover .overlay {
    opacity: 1;
  }
  .polycoq {
    background-image: url('./caroussel-imgs/3.jpg');
    background-position: center;
    width: 98%;
    height:400px;
    
}
.polycoq:hover .overlay {
    opacity: 1;
  }

  .p2  {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    height: 1110px;
    align-items: center;
    
   

}
  .sousse {
    background-image: url('./../../Assets/sousse.jpg');
    background-position: center;
    width: 100%;
    height: 500px;
}





  .sousse:hover .overlay {
    opacity: 1;
  }
  .jbaloust {
    background-image: url('./caroussel-imgs/2.jpg');
    background-position: center;
    width: 100%;
    height: 600px;
}
.jbaloust:hover .overlay {
    opacity: 1;
  }

  .p3  {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 1110px;
    justify-content: space-between!important;
    align-items: flex-start;
    justify-content: center;
  

}
  .rades {
    background-image: url('./../../Assets/Centrale\ electrique\ rades.jpg');
    background-position: center;
    width: 98%;
    height: 700px;
}

  .rades:hover .overlay {
    opacity: 1;
  }

  .matep {
    background-image: url('./caroussel-imgs/2.jpg');
    background-position: center;
    width: 98%;
    height: 400px;
}

  .matep:hover .overlay {
    opacity: 1;
  }
  .p {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width : 767px) {
  .slogan_page_projet {
    font-size: 20px;
    color: rgb(2, 2, 36);
    font-weight: bold;
    width: 78%;
    left: 13%;
    top: 16.5%;
}
.titre_page_projet {
  font-weight: bolder;
  font-size: 28px;
  font-family: sans-serif;
  color: #fa0f0c;
  left: 12.5%;
  top: 12.5%;
}
.bar_deco {
  position: relative;
  width: 15px;
  height: 154px;
  top: 0%;
  left: 3%;
  background-color: #fa0f0c;
}
.diaporama{
  position: relative;
  top: 0px;
  width: 80%;
  left: 10%;
 
}
.root_global_projet {
  background-image: url('./../../Assets/bg-ing.jpg');
padding: 20px;
}

}