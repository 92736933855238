* {
    box-sizing: border-box;
  }
.root_qs {
    display: flex;
    background-color: rgb(240, 240, 240);
    background-image: url("./fond.png");
    background-position: center; 
    padding: 20px;
    width: 100%;
    height: 600px;
}

.left_qs {
width:50%;
display: flex;
flex-direction: column;

}
.left_qs_top {
  height: 50%; 
  display: flex;
  flex-direction: column; 
  justify-content: flex-end;
  align-items: flex-end;
}
.left_nv_top {
  position: relative;
    left: -10%;
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-start;
}

.left_qs_bottom {
  height: 50%; 
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-end;
}
.left_nv_bottom {
  position: relative;
    left: -10%;
  display: flex;
  flex-direction: column; 
  justify-content: flex-start;
  align-items: flex-start;
}

.right_qs {
  width:50%;
  display: flex;
flex-direction: column;
}
.right_qs_top {
  display: flex;
  flex-direction: column;
  height: 50%;
  justify-content: flex-end;
  align-items: flex-start;
}
.right_qs_bottom {
  height: 50%;
}
.service_item {
  padding: 20px 0 20px 100px;
  color: rgb(28, 28, 65);
  font-size: 25px;
}
.txt_qs {
  padding:10px;
  width: 500px;
  font-size: 18px;
  font-weight: bold;
  color: rgb(90, 90, 90);
 

}

.slogan_qs {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
}
.title_qs {
  font-weight: bold;
  color: #fa0f0c;
  font-size: 20px;
}
.entete_qs {
  display: flex;
  padding-bottom: 3%;
  width: 500px;
  justify-content: space-between;
}
.title_slogan_qs {
  display: flex;
  flex-direction: column;
  width: 96%;
}
.bar-color-qs {
  background-color: #fa0f0c;
  width: 1%;
  height: 75%;
  
}

.teslabtn_qs .link-button_qs{
  text-decoration: none;
  color: white !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold !important;
  letter-spacing: 1.1px;
  z-index: 5;
      
  }
  .teslabtn_qs{
  border: 0;
  outline: 0 !important;
  background-color:#fa100ca9;
  min-width: 175px;
  border-radius: 30px;
  padding: 10px;
  position: relative;
  transition: all 1s ease-in-out;
  max-height: 56px;
  
  z-index: 1;
  }
  
  .teslabtn_qs::before {
  content: "";
  border: white 2px solid;
  width: 96%;
  height: 87%;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%,-50%);
  -webkit-transition: opacity 0.33s  ease-in-out;
  -moz-transition: opacity 0.33s  ease-in-out;
  -ms-transition: opacity 0.33s  ease-in-out;
  -o-transition: opacity 0.33s  ease-in-out;
  transition:  opacity 0.33s  ease-in-out;
  z-index: -1;
  }
  
  .teslabtn_qs:hover:before{
  opacity: 1;
  }
  .button_qs {
    padding-right: 100px;
  }

  @media (max-width : 767px) {
    .root_qs {
      display: flex;
      flex-direction: column;
      height: 1000px;
  }  
  .right_qs {
    width:100%;
  
  }
  .left_qs {
    width:100%;
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    }
    .entete_qs {
      padding-top: 5%;
      width: 100%;
      
    }
    .txt_qs {
      
      width: 100%;
     
    }
    .left_nv_bottom {
        left: 0%;
    }
    .left_nv_top {
      left: 0%;
    }
  }
