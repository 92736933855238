*{
   
    box-sizing: border-box;
}
.nosrealisations {
    display: flex;
    flex-direction: column;
}
.entete_nr {
    display: flex;
    left: 13%;
    position: relative;  
    animation: slide-up 2s;
    
}
@keyframes slide-up {
    from {
        opacity: 0.2;
        /* line-height: 200px; */
        left:-20%;
    }
    to {
        opacity: 1;
        /* line-height: 50px; */
        left:13%;
    }
}

/* entete titre slogan */
.titre_page_projet {
    position: relative;
    font-weight: bolder;
    font-size: 28px;
    font-family: sans-serif;
    color: #fa0f0c;
    left: 6%;
    top: 3%;

}
.slogan_page_projet {
   
    position: relative;
    font-size: 20px;
    color: rgb(2, 2, 36);
    font-weight: bold;
    width: 51%;
    left: 6%;
    top: 5%;
}
.bar_deco {
    position:relative;
    
    width: 15px;
    height: 110px;
   
    top: 11%;
    left: 3%;
  background-color: #fa0f0c;
}
  /* ============================= */
.root_nr
{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-image: url('./../../Assets/bg-ing.jpg');
    flex-wrap: wrap;
    padding-left: 15%;
    padding-right: 15%;

   
  

}

.realisation {
    width: calc(100% / 3);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding-top: 20px;
    padding-bottom: 20px;

}

.realisation .card 
{
    position: relative;
    width: 320px;
    height: 420px ;
   
}
.realisation .card .imgBx
{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
}

.realisation .card .imgBx img
{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.realisation .card .content
{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}
.realisation .card .content div

{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
    color:#ffff;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    text-shadow: 2px 2px #080707;
   
}
.realisation .card:hover .content div
{
    visibility:visible;
    opacity: 1;
    transform: translateY(50px);
}
.overlay {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #fa100c57;
    cursor: pointer;
    visibility: hidden;
  }
  .realisation .card:hover .overlay {
    visibility:visible;
    opacity: 1;
      
  }
  @media (max-width : 1000px)
  {
  .realisation {
    width: calc(100% / 2);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
    padding-bottom: 20px;

}
  }

  @media (max-width : 800px)
  {
  .realisation {
    width:100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    
    padding-bottom: 20px;

}
  }