
* {
    box-sizing: border-box;
  }
  .bg4 {
     
      display: flex;
      background-image: url('./../../../Assets/qhse.jpg');
      width: 100%;
      height: 500px;
  }
  .filtre4 {
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(8, 32, 43, 0.418); 
  }

  .txt4 {
      
   
      margin-left: 20%;
      margin-top: 5%;
      color: white;
      overflow-x: auto;
      overflow-y: auto;
     
     
  }
  .link {
      display: inline;
      text-decoration: none;
      color: antiquewhite;
      font-family: 'Open Sans', sans-serif !important;

  }

  .link a {
      color:#fa0f0c;
      font-weight: bold;
      font-family: 'Open Sans', sans-serif !important;
  }

  @media (max-width : 767px) { 
    .link a {

    }
    .txt4 {
      
   
        margin-left: 2%;
        margin-top: 5%;
        color: white;
        overflow-x: auto;
        overflow-y: auto;
       
       
    }

   }