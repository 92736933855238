*{
    box-sizing: border-box;
}
.entete_ing{
    display: flex;
    width: 100%;
    padding: 20px;
    background-color: #e4e7e9;
}
.trait_deco {
    background-color: #fa0f0c;
    width: 3px;
    height: 145px;
    margin-left: 11%;
}
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap');
.entete_ing p {
    width: 70%;
    padding: 10px;
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 18px;
    
}
.bg_entete {
   
   height: 395px;
}
.bg_entete .slogan_ing {
    position: relative;
    left: 10%;
    top:-50%;
    color: white;
    text-transform: uppercase;
    font-size: 55px !important;
    font-weight: bold;
    animation: slide-up 3s;
    text-shadow: 0 0 5px #080707;
  
  }
  @keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:10%;
      }
  }
  .ing_element {
  margin: 20px 15% ;
  background-color: rgba(128, 128, 128, 0.151);
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.493);
  }



.fond2 {
    background-image: url('./../../Assets/bg-ing.jpg');
    width: 100%;
}

.image_ing {
    display: flex;
    
}
.image_ing img {
    width: 90%;
    height: 225px;
    margin: 15px;
}

.txt_ing {
    display: flex;
    margin: 15px;
    width: 60%;
    
    
}
.trait_deco_description {
    background-color: #fa0f0c;
    width: 3px;
    height: 130px;
}
.txt_ing .description_ing {
    display: flex;
    flex-direction: column;
    width: 90%;
    padding: 10px;
}
.description_ing p {
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 16px;
}
.description_ing h5 {
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 18px;
    text-transform: uppercase; 
    text-decoration: none;
    color: #080707;
}

.btn_ing {
    /* position: absolute;
    margin-top: 31%!important;
    left: 61%; */
    background-color:#fa0f0c;
    border: none;
    color: white;
    padding: 16px 32px;
    text-align: center;
    font-size: 16px;
    margin: 4px 2px;
    opacity: 0.6;
    transition: 0.3s;
    width: 200px;
    
  }
  
  .btn_ing:hover {
      opacity: 1;
     
}

@media (max-width : 767px) {
    .bg_entete .slogan_ing {
        position: relative;
        width: 200px;
        padding: 20px;
        left: 0%;
        top: -53%;
        font-size: 35px;
    }
    .trait_deco {
        background-color: #fa0f0c;
        width: 3px;
        height: 420px;
        margin-left: 3%;
    }
    .entete_ing p {
        width: 90%;
        
        
    }
    .image_ing img {
        width: 90%;
        height: 200px;
        margin: 15px 0% 10px 30%;
        position: relative;
        left: -25%;
    }
    .txt_ing {
       
        width: 100%;
    }

}