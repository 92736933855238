.root_home_da {

  background-image: url('./../accueil/element4/image4.jpg');
    width: 100%;
    height: 500px;

}



.filtre_da {
position: relative;
width: 100%;
height: 100%;
z-index: 2;
background-color: rgba(8, 32, 43, 0.61); 
}
.entete_da {
  display: flex;
  flex-direction: column;
  padding: 10px;
  width: 25%;
  position: relative;
  left: 40%;
  top:10%;
  align-items: center;
  justify-content: center;
}
.secteur_da {
color: white;
font-weight: bold;
text-transform: uppercase;

font-family: 'Open Sans', sans-serif !important;
text-align: center;
}
.desc_secteur_da {
width: 120px;
color: white;
font-weight: bold;
}

.title1_da {
  font-size: 27px;
  font-weight: bold;
  color: #fa0f0c;
  text-transform: uppercase;
}
@media (max-width : 767px) {

  .title1_da {
    left: 7%;
    font-size: 23px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    position: relative;
    width: 400%;
    color: #fa0f0c;
}
}
.desc_da {
  font-size: 22px;
  color: white;
  font-weight: bold;
  width: 610px;
  
}
.activite_da {
  position: relative;
  top:20%;
  
  display: flex;
  width: 100%;
  flex-direction: row;
}
@media (max-width : 767px) {
.activite_da {
    flex-direction: column; 
    position: relative;
  top:10%;
  left:40%;
}  
.desc_da {
  font-size: 19px;
  color: white;
  width: 298px;
  text-align: center;
} 
.desc_secteur_da {
  width: auto;
  color: white;
  font-weight: bold;
}
.root_home_da {

  background-image: url('./../accueil/element4/image4.jpg');
    width: 100%;
   height: 1150px;   
    }
.filtre_da {
position: relative;
width: 100%;
height: 100%;
z-index: 2;
background-color: rgba(8, 32, 43, 0.61); 
}
.energie_da,
.agroalimentaire,
.batiments,
.industries
 {
  padding: 20px;
}
}
.energie_da,
.agroalimentaire_da,
.batiments_da,
.industries_da {
  width:25%;
  display:flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

}
