* {
    box-sizing: border-box;
}
.calo {
    position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;
}
.one1,
 .one2,
  .one3,
   .one4,
    .one5,
     .two {

    position: absolute;
    width:90%;
    height: 85%;
    margin-right:5%;
    margin-left: 5%;
    margin-top: 0%;
    margin-bottom: 0%;
    z-index: 1;
    background-position: center;
}

.one1 {
    
    background-image: url("./../../Assets/thermique.jpg");
    
}
.one2 {
    
    background-image: url("./../../Assets/frigorifique.jpg");
   

}
.one3 {
   
    background-image: url("./../../Assets/calorifugeage.jpg");
   

}

.one4 {
    
    background-image: url("./../../Assets/acoustique.jpg");
   

}
.one5 {
    
    background-image: url("./../../Assets/Aéraulique.jpg");
   
}
.two {
   
    background-color: rgba(13, 18, 20, 0.541);
    z-index: 2;

   
   
}
.three {
    display: inline!important; 
    position: absolute;
    width: 500px;
    height: 30px;
    left: 33%;
    top: 20%;
    z-index: 3;
    color: white;
    font-family: 'Open Sans', sans-serif !important;
   font-size: 40px;
    text-transform: uppercase;
    color: #fa0f0c;
    font-weight: bold;
}
    .para-ing {
        position: absolute;
        float: left;
        width: 500px;
        height: 100px;
        left: 20%;
        top: 50%;
        z-index: 3;
        color: white;
        font-family: 'Open Sans', sans-serif !important;
       font-size: 16px;
        text-transform: uppercase;
        overflow: hidden;

    }

    /* =====child of ingennierie style=== */

    .txt_ing_child {
        position: relative;
        width: 100%;
        height: 700px;
        background-image: url("./../../Assets/bg-ing.jpg");
        background-position: center;
        margin-top: -50px;
    }
    .descr_child {
        top:10%;
        position: absolute;
        line-height:200%;
        width: 70%;
        height: 100%;
        left:15%;
        font-size: 20px;
        font-family: 'Open Sans', sans-serif !important;
        color: rgb(1, 1, 49);
    }

    .img_ing_child {
        margin-top: 25px;
        margin-left: 10% ;
        margin-right: 10%;
    
    }
    .calo_child {
        position: relative;
    width: 100%;
    height: 500px;
    margin-bottom: 50px;

    }
    .one1_child,
 .one2_child,
  .one3_child,
   .one4_child,
    .one5_child,
     .two_child 
      {

    position: absolute;
    width:100%;
    height: 100%;
    margin-right:0%;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 0%;
    z-index: 1;
    background-position: center;
}

.one1_child {
    
    background-image: url("./../../Assets/thermique.jpg");
    
}
.one2_child {
    
    background-image: url("./../../Assets/frigorifique.jpg");
   

}
.one3_child {
   
    background-image: url("./../../Assets/calorifugeage.jpg");
   

}

.one4_child {
    
    background-image: url("./../../Assets/acoustique.jpg");
   

}
.one5_child {
    
    background-image: url("./../../Assets/Aéraulique.jpg");
   
}
.one_child_qualite {
    
    
    position: absolute;
    width:100%;
    height: 100%;
    margin-right:0%;
    margin-left: 0%;
    margin-top: 0%;
    margin-bottom: 0%;
    z-index: 1;
    background-position: center;
   
}
.two_child {
   
    background-color: rgba(13, 18, 20, 0.541);
    z-index: 2;

   
   
}
.three_child {
    display: inline!important; 
    position: absolute;
    width: 500px;
    height: 30px;
    left: 33%;
    top: 20%;
    z-index: 3;
    color: white;
    font-family: 'Open Sans', sans-serif !important;
   font-size: 40px;
    text-transform: uppercase;
    color: white;
}

    @media (max-width:991px) {

        .three {
            height: 30px;
            left: 25%;
            top: 20%;
            z-index: 3;
            color: white;
            font-family: 'Open Sans', sans-serif !important;
           font-size: 25px;
            text-transform: uppercase;
        }
            .para-ing {
                display: none;
    }
    .calo {
        position: relative;
        width: 100%;
        height: 300px;
        margin-bottom: 50px;
    }
    .plus {
        left: 20%;
        top: 70%;
    }
  
}
