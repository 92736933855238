*{
    box-sizing: border-box;
}
.banner_th {
background-image: url('./../../Assets/thermique.jpg');
background-position: center;
width: 100%;
height: 300px;
}
.title_rubrique_thermique {
    position: absolute;
    left: 10%;
    top: 30%;
    color: white;
    text-transform: uppercase;
    font-size: 45px !important;
    font-weight: bold;
    animation: slide-up 3s;
    text-shadow: 0 0 5px #080707;
}
  
  @keyframes slide-up {
      from {
          opacity: 0.2;
          /* line-height: 200px; */
          left:-20%;
      }
      to {
          opacity: 1;
          /* line-height: 50px; */
          left:10%;
      }
  }
.root_th {
background-image: url('./../../Assets/bg-ing.jpg');
width: 100%;
padding-top: 30px;
padding-bottom: 150px;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center
}
.txt_box_th {
    width: 70%;
    padding: 50px;
    margin: auto;
    display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color: rgba(128, 128, 128, 0.151);
    border-radius: 10px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.493);

}
@import url('https://fonts.googleapis.com/css2?family=Hind+Siliguri:wght@500&display=swap');
.root_th p {
    font-family: 'Hind Siliguri', sans-serif;
    font-size: 18px;
}
.title_th {
    font-size: 25px;
    font-weight: bold;
    font-family: 'Hind Siliguri', sans-serif;
    text-transform: uppercase;
}
.title_th:hover {
    text-decoration: underline;
}
 @media (max-width: 767px) {
    .root_th p {
        font-family: 'Hind Siliguri', sans-serif;
        font-size: 18px;
        width: 140%;
    }
    .title_th {
        width: 150%;
        
    }
    .title_rubrique_thermique {
        position: relative;
        left: 0;
     
        font-size: 40px !important;
   
    }
 }
