html {
scroll-behavior: smooth;
}


.caroussel{
    width: 100%;
    height: 450px;
    margin-left: auto;
    margin-right: auto;
    background-color:rgb(226, 211, 211);
}
#imgcar{
    
    height: 300px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;

}

#prjtitle{
    color:#fa0f0c;
}
#card{
    width: 320px;
    border-radius: 20px;
}
#localisation{
    width: 15px;
    margin-bottom: -20px;
}
.ele4{
    width: 100%;
    height: 500px;
    background-image: url('./element4/image4.jpg');
}
#filtre4{
    position: relative;
    width: 100%;
    height: 500px;
    z-index: 1;

}
.QHSETXT{
position: relative;
 color: white;
 margin-top:-350px ;
 margin-left: 250px;
 z-index: 9;

}
#detail2{
    margin-left: 140px;
    margin-top: 50px;
    font-size: large;
    color: #fa0f0c;
}
.fleche3
{
    width: 20px;
    height: 10px;
    margin-top: 14px;
}