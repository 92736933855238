.fil {
  display: block;
  position:relative;
  z-index: 2;
  width: 100%;
 height: 570px;
 background-color: rgba(13, 18, 20, 0.103); 
 margin-top: -570px;
 
  

}

.imgslide3 {
  position: relative;
 display: block;
  width: 100%;
  height: 570px;
  
  
  
}
.prev,
 .next{
  z-index: 3;
}

.sologan-slide {
  position:relative;
  z-index: 3;
  font-family: sans-serif;
  background-color: #fa0f0c ;
  left: 52%;
  top: 70%;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold;
  font-family: sans-serif;

}

.txt_slogan_slide {
  position:relative;
  width: 600px;
  height: 150px;
  z-index: 4;
  left: 10%;
  top: 22%;
  font-weight: bold;
  font-size: 35px;
  font-family: 'century-gothic-regular', sans-serif!important;
  text-transform: uppercase;
  color: rgb(250, 250, 250);
  animation: slide-up 2s;
  text-shadow: 0 0 5px #080707;
}

@keyframes slide-up {
    from {
        opacity: 0.2;
        /* line-height: 200px; */
        left:-20%;
    }
    to {
        opacity: 1;
        /* line-height: 50px; */
        left:10%;
    }

  

}

/* this class for a btn inside it tag <a> btn take the classname ==teslabtnSlide== */
.teslabtnSlide .link-button{
  text-decoration: none;
  color: white !important;
  font-family: 'Open Sans', sans-serif !important;
  font-weight: bold !important;
  letter-spacing: 1.1px;
  z-index: 5;
      
  }
  .teslabtnSlide{
  border: 0;
  outline: 0 !important;
  background-color:#fa100ca9;
  min-width: 175px;
  border-radius: 30px;
  padding: 10px;
  position: relative;
  transition: all 1s ease-in-out;
  max-height: 56px;
  left: 13%;
  top: 34%;
  z-index: 1;
  }
  
  .teslabtnSlide::before {
  content: "";
  border: white 2px solid;
  width: 96%;
  height: 87%;
  border-radius: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transform: translate(-50%,-50%);
  -webkit-transition: opacity 0.33s  ease-in-out;
  -moz-transition: opacity 0.33s  ease-in-out;
  -ms-transition: opacity 0.33s  ease-in-out;
  -o-transition: opacity 0.33s  ease-in-out;
  transition:  opacity 0.33s  ease-in-out;
  z-index: -1;
  }
  
  .teslabtnSlide:hover:before{
  opacity: 1;
  }
  
  /* =================================== */
  @media (max-width : 767px) {
    .txt_slogan_slide {
      position:relative;
      width:70%;
      height: 150px;
     
      z-index: 4;
      left: 23%;
        top: 44%;
      font-size: 20px;
      border-radius: 40px;
      color: rgb(241, 232, 232);
    
    }
    .teslabtnSlide{
      border: 0;
      outline: 0 !important;
      background-color: #fa0f0c;
      min-width: 145px;
      border-radius: 30px;
      padding: 10px;
      position: relative;
      transition: all 1s ease-in-out;
      max-height: 56px;
      left: 40%;
      top: 45%;
      z-index: 1;
      }
      .fil {
        display: block;
        position:relative;
        z-index: 2;
        width: 100%;
       height: 470px;
       background-color: rgba(13, 18, 20, 0.103); 
       margin-top: -570px;
       
        
      
      }
      
      .imgslide3 {
        position: relative;
       display: block;
        width: 100%;
        height: 470px;
        
        
        
      }
  }